import React, {FC, ForwardedRef, PropsWithChildren} from "react";
import {Container} from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";

interface Component {
    ref?: ForwardedRef<any>;
    sx?: SxProps<Theme>;
    style?: Record<string, string | number | boolean>;
}

interface AppContainerProps<T extends Component> extends PropsWithChildren {
    component?: FC<T>; // Cannot bind component to a specific type, sense the user can specify a custom component. This is
    // more complex for forwarded references.
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    sx?: SxProps<Theme>;
    style?: Record<string, string | number | boolean>;
}

interface AppContainerRefType extends React.FC<AppContainerProps<Component>> {
    <T extends Component>(props: AppContainerProps<T>): ReturnType<React.FC<AppContainerProps<T>>>;
}

const AppContainer: AppContainerRefType = React.forwardRef(
    function ({
                  component: Component = Container,
                  maxWidth = "xl",
                  sx,
                  ...props
              }, ref: React.ForwardedRef<HTMLDivElement>) {

        return (
            <Component
                ref={ref}
                sx={[
                    theme => ({
                        overflowX: "clip",
                        maxWidth: `${theme.breakpoints.values[maxWidth]}${theme.breakpoints.unit}`,
                        [theme.breakpoints.up('md')]: {
                            px: 3,
                            maxWidth: `${theme.breakpoints.values[maxWidth]}${theme.breakpoints.unit}`,
                        }
                    }),
                    ...(Array.isArray(sx) ? sx : [sx])
                ]}
                {...props}
            />
        );
    });

export default AppContainer;