import {skipToken} from "@reduxjs/toolkit/query";
import {createContext, PropsWithChildren, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../store/hooks";
import {selectUserAuth} from "../auth/AuthSlice";
import {
    addItemsToLocalCart,
    removeItemFromCart,
    removeItemsFromCart,
    selectLocalCart,
    useAddItemsToCartMutation,
    useDeleteCartItemMutation,
    useGetCurrentCartQuery,
    useGetPurchasesQuery
} from "./ShoppingSlice";
import {Cart, CreateCartItem, LocalCart} from "./ShoppingTypes";


/*
*   getCart -- return the users current cart
*   addItem -- add item to cart
*   updateCart -- update items in cart, add/remove/update quantity of items in cart
*   updateItem -- update a single item in cart
*   deleteItem -- delete item in cart
*   add checkout methods...
*/
interface CartManager {
    addItem: (item: CreateCartItem) => void,
    // updateCart: (checkout?: boolean) => Promise<Cart>,
    // updateItem: (itemId: string) => void,
    deleteItem: (itemId: string) => Promise<void>,
    getCart: () => [Cart | LocalCart, NetworkRequestState],
    getSubTotal: () => number;
    // getStripe: () => Promise<Stripe | null>;
    itemInCart: (itemId?: string) => boolean,
    // getClientSecret: () => [string | undefined, { status: LoadingState, errorMessage?: string }];
}

interface NetworkRequestState {
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    error?: any;
    refetch: any;
}


// const stripePromise = loadStripe(config.stripe.apiKey);


export const CartManagerContext = createContext<CartManager>({
    addItem: () => undefined,
    deleteItem: (itemId) => Promise.reject(),
    getCart: (): [LocalCart, NetworkRequestState] => ([{ items: {}, subTotal: 0 }, { isLoading: false, isFetching: false, isSuccess: false, isError: false, refetch: () => {}}]),
    getSubTotal: () => 0,
    itemInCart: (itemId) => false,
});

interface CartManagerProps {

}

function CartManager(props: PropsWithChildren<CartManagerProps>) {

    const dispatch = useDispatch();
    const { user, isAuthenticated, isAuthenticating } = useAppSelector(selectUserAuth);

    // const [createCart] = useCreateCartMutation();
    // const [updateCart] = useUpdateCartMutation();
    const [addItemsToCart] = useAddItemsToCartMutation();

    // const [addItem, addItemState] = useAdd
    const [deleteCartItem, deleteCartItemState] = useDeleteCartItemMutation();
    // const { data: purchases } = useGetPurchasesQuery(user ? { userId: user.id } : skipToken);

    const { data: cart, ...getCartState } = useGetCurrentCartQuery(
        (isAuthenticated && !isAuthenticating && user) ? { userId: user.id } : skipToken
    );
    // LocalCart is for temporary use only, update to server ASAP.
    const localCart = useAppSelector(selectLocalCart);

    useGetPurchasesQuery((isAuthenticated && !isAuthenticating && user) ? { userId: user.id } : skipToken);

    // provides online state updates, relies on "online"/"offline" event handlers set to the
    // window object, not completely accurate, will result in false positives
    // const [isOnline, setIsOnline] = useOnline();

    useEffect(() => {
        if (isAuthenticated && !isAuthenticating) {
            // Check if there is anything in the local cart that needs to be pushed to the remote cart
            if (Object.entries(localCart.items).length > 0) {
                addItemsToCart({
                    userId: user!.id,
                    items: Object.values(localCart.items)
                }).then(response => {
                    if ("error" in response) {
                        console.error("Error updating users cart");
                    }
                    console.log("Update users cart successful");
                    dispatch(removeItemsFromCart(Object.values(localCart.items).map(item => item.productId)));
                })
            }
        }
    }, [isAuthenticated, isAuthenticating, localCart, user?.id]);

    const cartActions: CartManager = {
        // add item to cart
        addItem: (item: CreateCartItem) => {
            if (isAuthenticated && !isAuthenticating) {
                addItemsToCart({
                    userId: user!.id,
                    items: [item]
                });
            } else {
                // User is not logged in, so serverStore item in local state
                dispatch(addItemsToLocalCart(item));
            }
        },
        deleteItem: async (productId) => {
            if (isAuthenticated && !isAuthenticating) {
                deleteCartItem({
                    userId: user!.id,
                    productId
                });
            } else {
                dispatch(removeItemFromCart({
                    productId
                }));
            }
        },
        getCart: (): [Cart | LocalCart, NetworkRequestState] => {

            if (user && cart) {
                return [cart, {
                    isLoading: getCartState.isLoading,
                    isFetching: getCartState.isFetching,
                    isSuccess: getCartState.isSuccess,
                    isError: getCartState.isError,
                    error: getCartState.error,
                    refetch: getCartState.refetch,
                }];
            }
            return [localCart, {
                isLoading: getCartState.isLoading,
                isFetching: getCartState.isFetching,
                isSuccess: getCartState.isSuccess,
                isError: getCartState.isError,
                error: getCartState.error,
                refetch: () => {},
            }];
        },
        getSubTotal: (): number => {
            if (cart) {
                return cart.subTotal;
            }
            return localCart.subTotal;
        },
        itemInCart: (itemId) => {
            if (itemId === undefined)
                return false;
            if (user && cart) {
                if (cart.items?.[itemId] !== undefined)
                    return true;
            }
            return localCart.items[itemId] !== undefined;
        }
    };

    return (
        <CartManagerContext.Provider
            value={cartActions}
            {...props}
        />
    );
}

interface UpdateCartProps {

    checkout: boolean;
    items: [{ productId: number, quantity: number }];
}


// Not in use yet...
// function useUpdateCart(updateProps: UpdateCartProps) {

//     const { user, isAuthenticated, isAuthenticating } = useSelector(selectUserAuth);
//     const { data: cart, ...getCartState } = useGetCurrentCartQuery(user ? { userId: user.id } : skipToken);
//     const localCart = useSelector(selectLocalCart);

//     const [updateState, setUpdateState] = useState<{ isPending: boolean, isSuccess: boolean, isError: boolean }>({
//         isPending: false,
//         isSuccess: false,
//         isError: false
//     });

//     return ({ update, user, cart, localCart }: {
//         update: { checkout?: boolean, items: BaseCartItem[] },
//         user: User, cart?: Cart, localCart?: LocalCart
//     }) => {
//         if (user) {
//             if (cart) {

//             }
//         }
//     }
// }


export default CartManager;