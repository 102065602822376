import {Image} from "../ImageUtils";
import * as React from "react";
import {PropsWithChildren, useState} from "react";

import {Box, SxProps, Theme} from "@mui/material";
import {Breakpoint} from "@mui/system/createTheme";
import ResponsiveImg from "../../../components/ResponsiveImg";


interface ImageGroupProps {
    images?: Image[];
    sx?: SxProps<Theme>;
    imageSx?: SxProps<Theme>;
    component?: React.ElementType;
    onClick?: React.MouseEventHandler;
    aspect: string | Partial<Record<Breakpoint, string>>;
}


/**
 * Selects the best image that will fit the provided container, If no compatible image is available, displays nothing.
 * @param images
 * @param sx
 * @param picture
 * @param children
 * @constructor
 */
export default React.forwardRef(function ImageGroup({
                                                        images = [],
                                                        sx,
                                                        imageSx,
                                                        component = Box,
                                                        children,
                                                        onClick,
                                                        aspect,
                                                        ...other
                                                    }: PropsWithChildren<ImageGroupProps>, ref) {

    const [imageIndex, setImageIndex] = useState(0);


    return (
        <Box
            ref={ref}
            sx={[(theme) => ({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#222",
                overflow: "clip",
                maxWidth: "100%",
            }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            component={component}
            {...other}
            onClick={onClick}>

            <ResponsiveImg
                images={images[imageIndex]?.sizes ?? []}
                alt={images[imageIndex]?.alt}
                sx={imageSx}
                aspect={aspect}
            />

            {children}
        </Box>
    );
});