import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, retry} from "@reduxjs/toolkit/query/react";
import {RootState} from "../../store/clientStore";

const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.MODE === "test" ? "http://localhost:5173/api" : "/api",
    prepareHeaders: (headers, {type, getState}) => {
        if (!headers.has("accept"))
            headers.set("accept", "application/json");

        if (type === "mutation") {
            headers.set("x-xsrf-token", (getState() as RootState).auth.csrf?.token ?? "");
        }
        return headers;
    }
});

const csrfBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
    async (args, api, extraOptions) => {
         if (api.type === "mutation") {
             // If the csrf token is null, get it first
             if ((api.getState() as RootState).auth.csrf == null) {
                 await api.dispatch(apiSlice.endpoints?.getCSRF.initiate());
                 // Fetch csrf token
                 await baseQuery('/auth/csrf', api, extraOptions);
             }
         }
        return baseQuery(args, api, extraOptions);
    };

const staggeredCsrfBaseQuery = retry(
    async(args, api, extraOptions) => {
        const result = await csrfBaseQuery(args, api, extraOptions);

        if (result.error?.status === 400 || result.error?.status === 401 || result.error?.status === 403 || result.error?.status === 404
            || result.error?.status === 500) {
            retry.fail(result.error);
        }

        return result;
    }, {
    maxRetries: 5
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: csrfBaseQuery, // staggeredCsrfBaseQuery,
    endpoints: builder => ({
        getCSRF: builder.query<{ token: string }, void>({
            query: () => ({
                url: '/auth/csrf',
                refetchOnReconnect: true,
            }),
            providesTags: ["CSRF", "Auth"]
        }),
    }),
    tagTypes: ["CSRF", "User", "Auth", "UserProfile", "Product", "Book",
        "BookPage", "BookChapter", "BookPageRange", "ChapterPages", "Image", "LibraryEntity",
        "Cart", "CartItem", "Group", "Purchases", "NotificationPref"]
});
