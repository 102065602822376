import React, {PropsWithChildren} from "react";

import {alpha,styled, SxProps, useTheme, useThemeProps} from "@mui/material";
import {mapRange} from "../../../utils/Utils";

export interface ProgressIndicatorRoot {
    position: "relative" | "static" | "absolute" | "sticky";
    backgroundColor: string;
    // backgroundImage: string;
}

export interface ProgressIndicatorProps extends Partial<ProgressIndicatorRoot> {
    percentage: number;
    sx?: SxProps;
}

interface ProgressIndicatorOwnerState extends ProgressIndicatorRoot {
}

const ProgressIndicatorRoot = styled('div', {
    name: "ProgressIndicator",
    slot: "root"
})<{ ownerState: ProgressIndicatorOwnerState }>(({theme, ownerState}) => ({
    backgroundColor: ownerState.backgroundColor,
    position: ownerState.position
}));


const mapX1 = mapRange(0, 1, 0, 110);
const mapX2 = mapRange(0, 1, -10, 100);


export default React.forwardRef<HTMLDivElement, PropsWithChildren<ProgressIndicatorProps>>(
    function ProgressIndicator({percentage, sx, ...inProps}, ref) {

        const theme = useTheme();
        const {position, backgroundColor, ...props} = useThemeProps({
            props: inProps,
            name: "ProgressIndicator"
        }) as ProgressIndicatorRoot;


        return (
            <ProgressIndicatorRoot ref={ref}
                                   ownerState={{
                                       position,
                                       backgroundColor
                                   }}
                                   sx={sx}
                                   style={{
                                       backgroundImage: `linear-gradient(to right, 
                                       ${alpha(theme.palette.primary[theme.palette.mode], .5)} ${mapX2(percentage)}%, 
                                       ${alpha(theme.palette.primary[theme.palette.mode], 0)} ${mapX1(percentage)}% )`
                                   }}
                                   {...props}
            />
        );
    });
