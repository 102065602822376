import React from "react";
import {Box, styled} from "@mui/material";

interface MenuSpacerProps {
    gap?: number;
}

// Replace MenuSpacer with this, maybe...
const Spacer = styled('div')(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0,1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
}));


export default React.forwardRef<HTMLDivElement, MenuSpacerProps>(
    function MenuSpacer({gap = 0}, ref) {

        return (
            <Box ref={ref}
                 sx={theme => ({
                     height: `${60 + 8 * gap}px`,
                     width: "100%",
                     [theme.breakpoints.up('sm')]: {
                         height: `${64 + 8 * gap}px`
                     }
                 })}
            />
        );
    });