import React, {PropsWithChildren, useMemo} from "react";
import {selectAuthorities, selectIsAuthenticated} from "../feature/auth/AuthSlice";
import {Authority} from "../feature/auth/AuthTypes";
import {useAppSelector} from "../store/hooks";

type Props = PropsWithChildren<{
    isAuthenticated?: boolean;
    hasAny?: Authority[];
    hasAll?: Authority[];
    alternative?: React.ReactNode;
}>

const ComponentGuard: React.FC<Props> = ({
                                             isAuthenticated: requiresAuthentication = true,
                                             hasAny = [],
                                             hasAll = [],
                                             alternative = "",
                                             children
                                         }) => {
    // Get users authorities
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const authorities = useAppSelector(selectAuthorities);

    const isAllowed: boolean = useMemo(() => {

        if (!requiresAuthentication)
            return !isAuthenticated;

        // if user is not authenticated, or they don't have any authorities deny access
        if (!isAuthenticated || !authorities)
            return false;

        // Verify that the user contains all required authorities
        if (!hasAll.reduce((acc, cur) =>
            (acc && authorities.includes(cur)), true))
            return false;

        // Verify the user contains any of the required authorities
        return hasAny.reduce((acc, cur) =>
            (acc || authorities.includes(cur)), hasAny.length === 0);

    }, [authorities, hasAll, hasAny, isAuthenticated, requiresAuthentication]);

    return (
        <>
            {isAllowed ? children : alternative}
        </>
    );
}

export default ComponentGuard;