import {Box, Typography, IconButton} from "@mui/material";
import React, {useMemo, useState} from "react";
import Carousel from "../../../components/Carousel";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {ProductGroup as ProductGroupType, ProductGroupRedux, ProductRedux} from "../ProductTypes";
import ProductItem from "./ProductItem";
import {selectGroupSettings, setGroupPosition} from "../../settings/SettingsSlice";
import {selectAuthorities} from "../../auth/AuthSlice";
import {authority} from "../../auth/AuthTypes";
import {selectAllProducts} from "../ProductSlice";
import {KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon} from "@mui/icons-material";

interface ProductSeriesProps extends ProductGroupRedux {
}

function isProduct(product: ProductRedux | undefined): product is ProductRedux {
    return (product as ProductRedux) !== undefined;
}

export default React.forwardRef<HTMLDivElement, ProductSeriesProps>(
    function ProductGroupViewer({id, title, slug, components}: ProductSeriesProps, ref) {

        const dispatch = useAppDispatch();
        const groupSettings = useAppSelector(state => selectGroupSettings(state, id));
        const [isOpen, setIsOpen] = useState<boolean>(true);
        const authorities = useAppSelector(selectAuthorities);

        const products = useAppSelector(selectAllProducts);
        const canViewProducts = useMemo(() => Boolean(authorities?.includes(authority.viewProduct)), [authorities]);

        const componentsToDisplay = useMemo(() => {
            const pAry = Object.values(components || {})
                .sort((a, b) =>
                    (a?.position ?? Infinity) - (b?.position ?? Infinity))
                .map(c => products[c.componentId]);

            return canViewProducts ? pAry : pAry.filter(p => p.visible);
        }, [components, products, canViewProducts]);

        if (componentsToDisplay.length >= 1)
            return (
                <Box ref={ref} py={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography>{title}</Typography>
                        <IconButton
                            sx={{visibility: "hidden"}}
                            onClick={() => setIsOpen(state => !state)}>
                            {isOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </Box>

                    <Carousel
                        position={groupSettings?.position ?? 0}
                        setPosition={(i) => dispatch(setGroupPosition({id, position: i}))}
                        responsive={{md: 2}}>
                        {componentsToDisplay.map((product) =>
                            <ProductItem
                                key={product.id}
                                id={product.id}
                                position={components![product.id].position}
                                product={product}
                            />
                        )}
                    </Carousel>
                </Box>
            );
    });
