export default function Scrollbar({
                                      track,
                                      thumb,
                                      active,
                                      width = "8px",
                                      radius = 4
                                  }: { track: string; thumb: string; active: string; width?: string; radius?: number; }) {
    return {
        scrollbarColor: track,
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: track,
            width: width,
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: radius,
            backgroundColor: thumb,
            border: `1px solid ${track}`
        },
        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: active,
        },
        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: active,
        },
        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: active,
        },
        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: track
        }
    };
}