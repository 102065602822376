function gcd(a: number, b: number): number {
    if (b < a)
        return gcd(b, a);

    if (a <= 0)
        return b;

    return gcd(b % a, a);
}


export function calculateImageAspect(width: number, height: number): string {
    const commonDivider = gcd(width, height);
    return (width / commonDivider) + "x" + (height / commonDivider);
}

export function findClosesAspect(width: number, height: number, aspects: number[][]) {
    for (const [w, h] of aspects) {
        const wr = width / w;
        const hr = height / h;
        if (wr == hr || (wr + 2 > hr && wr - 2 < hr))
            return `${w}/${h}`;
    }
    return undefined;
}

export type ImageSize = {
    image_id: string;
    index: number;
    original: boolean;
    source: string;
    width: number;
    height: number;
    aspect: string;
    storage: number;
}

export type Image = {
    id: string;
    alt: string;
    sizes: ImageSize[];
}

export const FILE_URL = `/api/file/image/stream/`;

export function transformSource(source: string) {
    return `${FILE_URL}${source}`;
}

export function transformImagePath(image: Image) {
    image.sizes.forEach(size => size.source = transformSource(size.source));
    return image;
}

export type ImageDimensions = Pick<ImageSize, "width" | "height">;

// TODO most of this isn't used anymore, need to clean up old code

export type Aspect = "3x4" | "16x9" | "16x7" | "40x21" | "540x283" | "2x3";

export type ImageType = "product";

export type SizeMap = Partial<Record<Aspect, { sizes: ImageDimensions[]}>>;

export function cssAspectRatioConverter(aspect?: Aspect | string) {
    return aspect?.replace(/[x,:/]/, " / ") ?? "";
}

export const imageMap: Record<ImageType, SizeMap> = {
    product: {
        "3x4": {
            sizes: [{
                width: 120,
                height: 160
            }, {
                width: 144,
                height: 192
            }, {
                width: 180,
                height: 240
            }]
        },
        "16x9": {
            sizes: [{
                width: 400,
                height: 225
            }, {
                width: 720,
                height: 405
            }, {
                width: 800,
                height: 450
            },{ // Pinterest share size
                width: 1040,
                height: 585
            },{ // Twitter share size
                width: 1200,
                height: 675
            }]
        },
        "16x7": {
            sizes: [{
                width: 400,
                height: 175
            }, {
                width: 720,
                height: 315
            }, {
                width: 1040,
                height: 455
            }, {
                width: 1200,
                height: 525
            }]
        },
        // Facebook share image size
        "40x21": {
            sizes: [{
                width: 1200,
                height: 630
            }]
        },
        // Instagram share image size
        "540x283": {
            sizes: [{
                width: 1080,
                height: 566
            }]
        },
        "2x3": {
            sizes: [{
                width: 600,
                height: 900
            },{
                width: 1000,
                height: 1500
            }]
        }
    }
};

export type SocialComp = "facebook" | "twitter" | "pinterest";

const socialMediaSpec: Record<SocialComp,{aspect: Aspect; size: ImageDimensions }> = {
    facebook: {
        aspect: "40x21",
        size: {
            width: 1200,
            height: 630
        }
    },
    // instagram: {
    //     aspect: "540x283",
    //     size: {
    //         width: 1080,
    //         height: 566
    //     }
    // },
    twitter: {
        aspect: "16x9",
        size: {
            width: 1600,
            height: 900
        }
    },
    pinterest: {
        aspect: "2x3",
        size: {
            width: 1000,
            height: 1500
        }
    }
};

export function getAllSocialImages(images: Image[]): Partial<Record<SocialComp, ImageSize>> {
    return Object.keys(socialMediaSpec)
        .map(social => [social, getSocialImage(images, (social as SocialComp))])
        .filter(socialImage => socialImage[1] !== undefined)
        .reduce((acc, [social, image]) => ({
            ...acc,
            [social as SocialComp]: image
        }), {});
}

export function getSocialImage(images: Image[], social: SocialComp): ImageSize | undefined {
    return getImageAtAspect(
        images,
        socialMediaSpec[social].aspect,
        socialMediaSpec[social].size
    );
}

export function getImageAtAspect(images: Image[], aspect: Aspect, dimensions: ImageDimensions): ImageSize | undefined {
    for (const image of (images ?? [])) {
        const sizes = image.sizes
            .filter(size => size.aspect === aspect)
            .filter(size => size.width >= dimensions.width)
            .sort((a,b) => a.width - b.width);

        if (sizes.length >= 1)
            return sizes[0];
    }
}

export type CreateImageSet = Pick<Image, "id" | "alt"> & {
    images: Record<string, CreateImageItem>
};

export type CreateImageItem = {
    file: File;
    aspect: string; // May be able to remove this value
    sizes: ImageDimensions[];
}

export type AddAspect = {
    id: string;
    images: CreateImageItem[];
}

export type ImageMap<T> = Record<string, T>;

export const generateImageSizes = {
    thumbnail: "Thumbnail",
    product: "Product",
    viewer: "Viewer"
} as const;

export type GenerateImageSize = keyof typeof generateImageSizes;



