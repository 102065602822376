import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {Cart, CreateCartItem, LocalCart, paymentStatus, PurchasedProduct, Refund, RefundCart} from "./ShoppingTypes";
import {apiSlice} from "../api/apiSlice";
import {Audited, Collection, Pageable} from "../api/types";
import {RootState} from "../../store/clientStore";
import {getEpoch} from "../../utils/Utils";
import {extendedAuthApi} from "../auth/AuthSlice";
import {selectProductId} from "../product/ProductSlice";


export const extendedShoppingApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // FIXME this is not right
        getCarts: builder.query<Collection<Cart>, { userId: string } & Pageable>({
            query: (({userId, ...params}) => ({
                url: `/user/${userId}/cart`,
                method: "GET",
                params
            })),
            providesTags: (result, error, arg) =>
                result ? [...result.data.map(cart => ({
                    type: "Cart" as const,
                    id: cart.id
                })), {type: "Cart", id: "PARTIAL-LIST"}] : ["Cart"]
        }),
        getPurchases: builder.query<string[], { userId: string }>({
            query: ({userId}) => ({
                url: `/user/${userId}/cart/purchases`,
                method: "GET"
            }),
            providesTags: ["Purchases"]
        }),
        getCurrentCart: builder.query<Audited<Cart>, { userId: string }>({
            query: (({userId}) => ({
                url: `/user/${userId}/cart/current`,
                method: "GET"
            })),
            providesTags: (result, error, arg) =>
                result ? [{type: "Cart", id: result.id}, "Cart"] : ["Cart"]
        }),
        getCart: builder.query<Cart, { cartId: string, userId: string }>({
            query: (({userId, cartId}) => ({
                url: `/user/${userId}/cart/${cartId}`,
                method: "GET"
            })),
            transformResponse: (response: Cart, meta, arg) => {
                return {
                    ...response,
                    fulfilledTimeStamp: getEpoch()
                };
            },
            providesTags: (result, error, arg) =>
                result ? [{type: "Cart", id: result.id}, "Cart"] : ["Cart"]
        }),
        addItemsToCart: builder.mutation<Cart, { userId: string, items: CreateCartItem[] }>({
            query: (({userId, items}) => ({
                url: `/user/${userId}/cart`,
                method: "POST",
                body: items
            })),
            async onQueryStarted({userId, items},
                                 {dispatch, queryFulfilled}) {

                // Update local cart optimistically, no response has been received yet.
                const manualUpdate = dispatch(
                    extendedShoppingApi.util.updateQueryData("getCurrentCart", {userId},
                        (draft) => {
                            if (!draft.items)
                                draft.items = {};

                            items.forEach(item => {
                                // @ts-ignore: draft.items is guaranteed to exist
                                draft!.items[item.productId] = {
                                    cartId: "",
                                    productId: item.productId,
                                    quantity: item.quantity,
                                    price: item.price,
                                    createdDate: 0,
                                    lastModifiedDate: 0,
                                }
                            })
                        }));
                // Get result from api
                try {
                    const {data} = await queryFulfilled;
                    // TODO: Diff local cart with remote cart, display error for items not added!
                    dispatch(extendedShoppingApi.util.upsertQueryData("getCurrentCart", {userId}, data));
                } catch (error) {
                    console.error("Error adding items to cart: ", error);
                    manualUpdate.undo();
                }
            },
        }),
        checkout: builder.mutation<Cart, { userId: string, cartId: string }>({
            query: (({userId, cartId}) => ({
                url: `/user/${userId}/cart/${cartId}/checkout`,
                method: "POST"
            })),
            async onQueryStarted({userId, cartId},
                                 {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;

                    // Update the current cart with the stripe secret,
                    // this is required for the checkout process
                    dispatch(extendedShoppingApi.util.updateQueryData("getCurrentCart", {userId},
                        draft => {
                            Object.assign(draft, data);
                        }));

                } catch (error) {
                    // TODO: Fix this
                    console.error("Error during checkout");
                    console.error(error);
                }
            },
            invalidatesTags: ["Purchases"]
        }),
        refundCartItem: builder.mutation<Refund, { userId: string, cartId: string, refundCart: RefundCart }>({
            query: ({userId, cartId, refundCart}) => ({
                url: `/user/${userId}/cart/${cartId}/refund`,
                method: "POST",
                body: refundCart
            }),
            // This does not work right now, I believe that it doesn't work because I need to match the request params
            // async onQueryStarted({userId, cartId, refundCart}, {dispatch, queryFulfilled}) {
            //     try {
            //         const { data } = await queryFulfilled;
            //         console.log("Data: ", data);
            //
            //         dispatch(extendedShoppingApi.util.updateQueryData("getCarts", {userId},
            //             draft => {
            //                 // If we find the cart
            //                 const cart = draft.data.find(cart => cart.id === cartId);
            //                 console.log("Cart: ", cart)
            //                 if (cart) {
            //
            //                     if (cart.refund === undefined) {
            //                         cart.refund = [];
            //                     }
            //                     console.log("Update refund items");
            //                     cart.refund.push(data)
            //                 }
            //
            //             }));
            //     } catch (error) {
            //         console.log("refund response error: ", error);
            //     }
            // }
            invalidatesTags: ["Purchases", "Cart"]
        }),
        deleteCartItem: builder.mutation<Cart, { userId: string, productId: string }>({
            query: ({userId, productId}) => ({
                url: `/user/${userId}/cart/item/${productId}`,
                method: "DELETE"
            }),
            async onQueryStarted({userId, productId},
                                 {dispatch, queryFulfilled}) {
                const manualUpdate = dispatch(extendedShoppingApi.util.updateQueryData("getCurrentCart", {userId},
                    draft => {
                        if (draft.items)
                            delete draft.items[productId];
                    }));

                try {
                    const {data} = await queryFulfilled;
                    dispatch(extendedShoppingApi.util.updateQueryData("getCurrentCart", {userId},
                        draft => {
                            Object.assign(draft, data);
                        }));

                } catch (error) {
                    console.error("Error deleting item from cart, item: ", productId);
                    manualUpdate.undo();
                }
            }
        }),
    })
});


interface InitialState {
    cart: LocalCart;
    purchases: Record<string, string>;
}

const cartInitialState = {
    subTotal: 0,
    items: {}
}

const initialState: InitialState = {
    cart: cartInitialState,
    purchases: {}
}

const ShoppingSlice = createSlice({
    name: "shopping",
    initialState,
    reducers: {
        addItemsToLocalCart(state, {payload, type}: PayloadAction<CreateCartItem>) {
            state.cart.items[payload.productId] = payload;
            // Recalculate total
            const cartItems = current(state.cart.items);
            state.cart.subTotal = Object.values(cartItems).reduce((acc, cur) => {
                return acc + (cur.price.basePrice * cur.quantity);
            }, 0);
        },
        removeItemFromCart(state, {payload}: PayloadAction<{ productId: string }>) {
            if (state.cart.items[payload.productId]) {
                const item = state.cart.items[payload.productId];
                state.cart.subTotal -= item.price.basePrice * item.quantity;
                delete state.cart.items[payload.productId];
            }
        },
        removeItemsFromCart(state, {payload, type}: PayloadAction<string[]>) {
            payload.forEach(itemId => {
                ShoppingSlice.caseReducers.removeItemFromCart(state, {payload: {productId: itemId}, type});
            });
        },
        addPurchasedItems(state, {payload, type}: PayloadAction<string[]>) {
            payload.forEach(item => {
                state.purchases[item] = item;
            });
        },
        clearCart(state, {payload, type}: PayloadAction) {
            state.cart = cartInitialState;
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(extendedShoppingApi.endpoints.getCurrentCart.matchFulfilled,
                (state, {payload, meta}) => {
                })
            .addMatcher(extendedShoppingApi.endpoints.getCart.matchFulfilled,
                (state, {payload, meta}) => {

                    // if (payload.status === paymentStatus.SUCCEEDED) {
                    //     Object.values(payload?.items ?? {})
                    //         .forEach(item => {
                    //             state.purchases[item.productId] = item.productId;
                    //         });
                    // }
                })
            .addMatcher(extendedShoppingApi.endpoints.getCarts.matchFulfilled,
                (state, action) => {

                })
            .addMatcher(extendedShoppingApi.endpoints.getPurchases.matchFulfilled,
                (state, {type, payload}) => {
                    state.purchases = payload.reduce<Record<string, string>>((acc, cur) => {
                        acc[cur] = cur;
                        return acc;
                    }, {});
                })
            .addMatcher(extendedAuthApi.endpoints.logout.matchFulfilled, (state => {
                state.purchases = {}
            }))
    }
});

export const selectLocalCart = (state: RootState) => state.shopping.cart;
export const selectPurchases = (state: RootState): Record<string, string> => state.shopping.purchases;
export const selectOwnsProduct = (state: RootState, productId?: string): boolean => productId ? Boolean(selectPurchases(state)[selectProductId(state, productId)]) : false;

export const {
    useGetCartQuery,
    useGetCurrentCartQuery,
    useGetPurchasesQuery,
    useGetCartsQuery,
    useAddItemsToCartMutation,
    useDeleteCartItemMutation,
    useCheckoutMutation,
    useRefundCartItemMutation,
} = extendedShoppingApi;
export const {
    addItemsToLocalCart,
    addPurchasedItems,
    removeItemFromCart,
    removeItemsFromCart,
    clearCart,
} = ShoppingSlice.actions;

export default ShoppingSlice.reducer;