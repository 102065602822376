import React, {useEffect} from 'react';
import AppMenu from "./components/AppMenu";
import {Outlet, useLocation} from "react-router-dom";
import CartManager from "./feature/shopping/CartManager";
import {MenuControl} from "./components/MenuController";
import {Box} from "@mui/material";
import {useGetAuthQuery} from "./feature/auth/AuthSlice";
import {HelmetProvider} from "react-helmet-async";
import ReactGA from "react-ga4";
import {InstallPWA} from "./components/InstallPWAContext";

const helmetContext = {};

function App() {
    const location = useLocation();

    useGetAuthQuery();

    useEffect(() => {
        if (import.meta.env.MODE === "product")
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname,
            });
    }, [location]);

    return (
        <HelmetProvider context={helmetContext}>
            <InstallPWA>
                <CartManager>
                    <MenuControl>
                        <Box sx={(theme) => ({
                            maxWidth: theme.breakpoints.values.xl,
                            minHeight: "100vh",
                            mx: "auto",
                            backgroundColor: theme.palette.background.paper,
                            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                            padding: 0,
                            position: "relative",
                        })}>
                            <AppMenu />
                            <Outlet/>
                            {/*<BottomMenu />*/}
                        </Box>
                    </MenuControl>
                </CartManager>
            </InstallPWA>
        </HelmetProvider>
    );
}

export default App;
