import { Authority, authority } from "../auth/AuthTypes";
import { PurchasedProduct } from "../shopping/ShoppingTypes";
import {Product, ProductRedux} from "./ProductTypes";

export function canOpenBook(product: ProductRedux | undefined, authorities?: Authority[]) {
    return product && (product.enabled || authorities?.includes(authority.viewBook))
}


// export function canPurchase(product: Product | undefined, purchasedProducts: Record<string, PurchasedProduct>) {
//     return product && product.purchasable &&
//         (purchasedProducts[product.id] ?
//             product.purchaseLimit < purchasedProducts[product.id].quantity : true);
// }
