export function mapRange(inputStart: number, inputEnd: number, outStart: number, outEnd: number) {
    const slope = (outEnd - outStart) / (inputEnd - inputStart);
    return function _Mapper(input: number) {
        return outStart + (slope * (input - inputStart));
    }
}


export function arrayChunk<T>(ary: T[], size: number):T[][] {
    const result: T[][] = [];
    const rows = Math.ceil(ary.length / size);

    for (let x = 0; x < rows; ++x) {
        result[x] = ary.slice(x * size, (x + 1) * size);
    }

    return result;
}

export interface Offset {
    m?: number;
    h?: number;
    d?: number;
}

function isOffset(offset: number | Offset): offset is Offset {
    return typeof offset !== 'number';
}

/**
 * Returns the epoch timestamp in seconds
 * @param offset
 * @returns
 */
export function getEpoch(offset: number | Offset = 0) {
    const delta = isOffset(offset) ?
        (offset?.m ?? 0) * 60 + (offset?.h ?? 0) * 3600 + (offset?.d ?? 0) * 86400 : offset;

    return Math.floor((new Date().getTime() / 1000) + delta);
}

export function offsetEpoch(time: number | undefined, offset: number | Offset = 0) {
    if (time === undefined)
        return 0;
    if (isOffset(offset))
        return time + (offset?.m ?? 0) * 60 + (offset?.h ?? 0) * 3600 + (offset?.d ?? 0) * 86400;
    return time + offset;
}

/**
 * Merge two objects key/value pairs into one object
 * @param obj1
 * @param obj2
 */
export function mergeObjectKeyValues<T extends {}>(obj1: T | undefined, obj2: T | undefined) {

    // eslint-disable-next-line eqeqeq
    if (obj1 == obj2)
        return true;

    if (obj1 === undefined || obj2 === undefined)
        return false;

    if (Object.keys(obj1).length !== Object.keys(obj2).length)
        return false;

    return Object.entries(obj1)
        .reduce((acc, [key, value]) => {
            if (obj2[key as keyof T] === value)
                return acc;
            return false;
        }, true);
}


export function throttle<R>(func: (...args: any[]) => R, delay: number) {

    let previousCall = 0;
    return (...args: unknown[]) => {
        let now = new Date().getTime();
        console.log("Time: ", now - previousCall, now, previousCall);
        if ((now - previousCall) > delay) {
            console.log("execute function");
            previousCall = now;
            return func(...args);
        }
    }
}

export function debounce(fnc: (...args: any[]) => void, delay: number) {

    let timer: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => fnc(...args), delay);
    };
}


export async function retry<R>(fn: () => R, maxAttempts: number, maxDelay: number = 1000): Promise<R> {
    async function execute(attempt: number): Promise<R> {
        try {
            return await fn()
        } catch (err) {
            if (attempt <= maxAttempts) {
                const delay = random(maxDelay / 2, maxDelay) * attempt;
                console.error(`Retrying after ${delay} milliseconds due to:`, err)
                return delayFn(() => execute(attempt + 1), delay)
            } else {
                throw err
            }
        }
    }

    return execute(1);
}

function delayFn<R>(fn: () => R, offset: number): Promise<R> {
    return new Promise<R>(resolve => {
        setTimeout(() => resolve(fn()), offset);
    });
}

function random(min: number, max: number): number {
    return Math.floor((Math.random() * (max - min)) + min);
}

function request(failCount: number) {
    return () => new Promise((resolve, reject) => {
        setTimeout(() => {
            if (failCount-- > 0)
                reject("error response");
            else resolve("success response");
        });
    });
}

async function testRetry() {
    try {
        await retry(request(2), 3)
    } catch (error) {
        console.log("Failure: ", error);
    }
}

export function objectsEqual(objA: Record<any, any>, objB: Record<any, any>): boolean {
    if (Object.keys(objA).length !== Object.keys(objB).length)
        return false;

    return Object.entries(objA).every(([key, value]) => {
        if (typeof value === "object") {
            if (typeof objB[key] !== "object")
                return false;
            return objectsEqual(value, objB[key]);
        }
        return value === objB[key];
    });
}


// testRetry();

