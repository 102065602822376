import React, {PropsWithChildren, useState} from "react";
import AppContainer from "../../components/AppContainer";
import DisplayError from "../../components/DisplayError";
import MenuSpacer from "../../components/MenuSpacer";
import ProgressLoader from "../../components/ProgressLoader";
import {useAppSelector} from "../../store/hooks";
import {transformErrorMessage} from "../../utils/StringUtils";
import {selectAuthorities} from "../auth/AuthSlice";
import {authority} from "../auth/AuthTypes";
import {selectAllGroups, useGetAllGroupsQuery} from "../product/ProductSlice";
import ProductGroup from "../product/components/ProductGroupViewer";
import {ProductGroupRedux} from "../product/ProductTypes";
import {Helmet} from "react-helmet-async";
import {alpha, Box, Typography} from "@mui/material";
import ResponsiveImg from "../../components/ResponsiveImg";


const DisplaySeries = ({children = []}: { children: ProductGroupRedux[] | undefined }) =>
    children
        // Only display series if it contains an item.
        .filter(group => Object.values(group.components || {}).length > 0)
        .map(group =>
            <ProductGroup key={group.id} {...group} />
        );

const HomeWrapper = ({children}: PropsWithChildren) => (
    <AppContainer>
        <Helmet prioritizeSeoTags>
            <title>Aurora Books</title>
            <meta name="description"
                  content="Discover Aurora Books – where dark fantasy comes to life. Dive into a captivating library of mature, spellbinding fantasy novels crafted for young adults who crave thrilling worlds, complex characters, and intense storylines. With a sleek design and a reading experience that keeps you lost in the pages, Aurora Books is where stories find a home and readers find magic. Start your adventure today."/>
        </Helmet>
        <MenuSpacer/>

        {children}

    </AppContainer>
);


const heroImg = [
    {
        source: "images/hero-200.webp",
        width: 200,
        height: 115
    }, {
        source: "images/hero-300.webp",
        width: 300,
        height: 173
    }, {
        source: "images/hero-400.webp",
        width: 400,
        height: 230
    }
];

const Hero = () => (
    <Box sx={theme => ({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    })}>
        <Typography variant="h1"
                    sx={theme => ({
                        fontSize: "1.05rem",
                        color: alpha(theme.palette.text.primary, 0.7),
                        maxWidth: "300px",
                        [theme.breakpoints.up('sm')]: {
                            fontSize: "1.2rem"
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: "1.3rem"
                        }
                    })}>Where Dark Tales and Epic Adventures Come to Life</Typography>
        {/*<Typography color="primary.light" align="center" sx={theme => ({*/}
        {/*    fontSize: "0.9rem",*/}
        {/*})}>Your next adventure awaits</Typography>*/}

        <ResponsiveImg
            images={heroImg}
            alt="Aurora Books"
            sx={{
                width: "50%",
                maxWidth: "300px"
            }}
        />
    </Box>
);

function Home() {

    const authorities = useAppSelector(selectAuthorities);
    const groups = useAppSelector(selectAllGroups);

    const [{page, size, expand, filter}, setQuerySeries] = useState<{
        page: number;
        size: number;
        expand?: string;
        filter?: string;
    }>({
        page: 0,
        size: 10,
        expand: "component",
        filter: authorities?.includes(authority.viewProduct) ? "" : "visible==true"
    });

    const {
        isFetching,
        isError,
        error,
    } = useGetAllGroupsQuery({page, size, expand, filter});

    if (isFetching && Object.values(groups).length === 0)
        return (
            <HomeWrapper>
                <ProgressLoader
                    sx={{height: "calc(100vh - 120px)"}}
                    size="5rem"/>
            </HomeWrapper>
        );

    if (isError && Object.values(groups).length === 0) {
        return (
            <HomeWrapper>
                <DisplayError
                    title="Error Loading Data"
                    message={"Unable to load book catalog."}
                    sx={{height: "calc(100vh - 120px)"}}
                    errorMessage={transformErrorMessage(error)}
                />
            </HomeWrapper>
        );
    }

    return (
        <HomeWrapper>
            <Hero/>
            <DisplaySeries>{Object.values(groups)}</DisplaySeries>
            <MenuSpacer gap={6}/>
        </HomeWrapper>
    );
}

export default Home;