import {createContext, PropsWithChildren, useEffect, useRef, useState} from "react";


export const InstallPWAContext = createContext<{ canInstallPWA: Boolean; installPWA: () => Promise<void> }>({
    canInstallPWA: false,
    installPWA: () => Promise.reject(),
});


export function InstallPWA(props: PropsWithChildren<{}>) {

    const [canInstallPWA, setCanInstallPWA] = useState(false);
    const installPwaEvent = useRef<Event | null>();

    useEffect(() => {
        const listener = window.addEventListener("beforeinstallprompt", (event: Event) => {
            event.preventDefault();
            installPwaEvent.current = event;
            setCanInstallPWA(true);
        });
        // @ts-ignore
        return () => window.removeEventListener("beforeinstallprompt", listener);
    }, []);

    useEffect(() => {
        const listener = window.addEventListener("appinstalled", () => {
            setCanInstallPWA(false);
        });
        // @ts-ignore
        return () => window.removeEventListener("appInstalled", listener);
    }, []);

    const handleInstallPwa = async () => {
        if (!installPwaEvent.current) {
            return;
        }

        // @ts-ignore
        const result = await installPwaEvent.current.prompt();
        console.log(`Install prompt was : ${result?.outcome}`);

        setCanInstallPWA(false);
        installPwaEvent.current = null;
    }

    return (
        <InstallPWAContext.Provider
            value={{
                canInstallPWA,
                installPWA: handleInstallPwa
            }}
            {...props}
        />
    );
}