import {apiSlice} from "../api/apiSlice";
import {Collection, Pageable, User, UserProfile} from "../api/types";

export const extendedUserApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query<Collection<User>, Pageable>({
            query: ({page = 0, size = 25, sort, filter, expand}) => ({
                url: `/user`,
                method: "GET",
                params: {page, size, sort, filter, expand}
            }),
            providesTags: (result, error, page) =>
                result ? [...result.data.map(user =>
                        ({type: "User" as const, id: user.id})),
                        {type: "User", id: "PARTIAL-LIST"}] :
                    [{type: "User", id: "PARTIAL-LIST"}]
        }),
        getUser: builder.query({
            query: ({id, expand = ""}) =>
                `/user/${id}?expand=${expand}`,
            providesTags: (result, error, arg) =>
                result ? [{type: "User", id: result.id}, "User"] : ["User"],
            keepUnusedDataFor: 60 * 30
        }),
        getUserProfile: builder.query<UserProfile, { id: string }>({
            query: ({id}) =>
                `/user/${id}/profile`,
            providesTags: (result, error, arg) => result ?
                [{type: "UserProfile", id: result.id}, {type: "UserProfile", id: "PARTIAL-LIST"}] :
                [{type: "UserProfile", id: "PARTIAL-LIST"}],
            keepUnusedDataFor: 60 * 30
        }),
        updateUserProfile: builder.mutation<void, UserProfile>({
            query: ({id, ...update}) => ({
                url: `/user/${id}/profile`,
                method: "POST",
                body: update
            }),
            async onQueryStarted({id, ...update},
                                 {dispatch, queryFulfilled}) {
                const result = dispatch(
                    extendedUserApi.util.updateQueryData("getUserProfile", {id}, (draft) => {
                        Object.assign(draft, update);
                    })
                );

                try {
                    await queryFulfilled;
                } catch (error) {
                    console.error("Error updating user profile: ", error);
                    result.undo();
                }
            }
        }),
        createUser: builder.mutation<null, { email: string; password: string; terms: boolean; }>({
            query: user => ({
                url: "/user",
                method: "POST",
                body: user
            })
        }),
        updatePassword: builder.mutation<void, { id: string, password: string, newPassword: string }>({
            query: ({id, password, newPassword}) => ({
                url: `/user/${id}/update-password`,
                method: "POST",
                body: {password, newPassword}
            })
        })
    })
});

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} = extendedUserApi;