export const RoutePath = {
    home: "/",
    settings: "/settings",
    contact: "/contact",
    about: "/about",
    faq: "/faq",
    admin: {
        route: "/admin",
        name: "admin",
        product: {
            route: "/admin/product",
            name: "product",

            new: {
                name: "new",
                route: "/admin/product/new"
            },
        },
        image: {
            route: "/admin/image",
            name: "image",
            upload: {
                route: "admin/image/upload",
                name: "upload"
            },
            edit: {
                route: "admin/image/:imageId"
            }
        },
    },
    auth: {
        route: "/auth",
        name: "auth",
        signIn: {
            route: "/auth/sign-in",
            name: "sign-in"
        },
        newAccount: {
            name: "new-account",
            route: "/auth/new-account"
        },
        activateAccount: {
            route: "/auth/activate-account",
            name: "activate-account"
        },
        sendActivateAccountCode: {
            route: "/auth/send-activate-account-code",
            name: "send-activate-account-code"
        },
        resetPassword: {
            route: "/auth/reset-password",
            name: "reset-password"
        },
        account: {
            route: "/auth/account",
            name: "account"
        },
        oauth: {
            name: "oauth2/:provider",
            route: "/auth/oauth2/:provider",
            google: {
                route: "/auth/oauth2/google",
                name: "google",
                link: "/auth/oauth2/google"
            }
        }
    },
    product: {
        route: "/product",
        name: "product",
        edit: {
            name: "edit",
            route: "/product/:productId/edit"
        },
        item: {
            route: "/product/:productId",
            name: ":productId",
            edit: {
                route: "/product/:productId/edit",
                name: "edit"
            }
        }
    },
    series: {
        route: "/series",
        name: "series",
        new: {
            route: "/series/new",
            name: "new"
        },
        edit: {
            route: "/series/:seriesId/edit",
            name: "edit"
        }
    },
    book: {
        route: "/book",
        name: "book",
        item: {
            route: "/book/:bookId",
            name: ":bookId"
        },
        edit: {
            route: "/book/:bookId/edit",
            name: ":edit"
        },
        addContent: {
            route: "/book/:bookId/add-content",
            name: "add-content"
        },
        content: {
            route: "/book/:bookId/content",
            name: "content"
        },
        page: {
            route: "/book/:bookId/page/:pageNumber",
            name: "page"
        },
        pages: {
            route: "/book/:bookId/pages",
            name: "pages"
        },
        chapters: {
            route: "/book/:bookId/chapters",
            name: "chapters"
        }
    },
    cart: {
        route: "/cart",
        name: "cart",
        checkout: {
            route: "/cart/checkout",
            name: "checkout"
        }
    },
    orderHistory: {
        route: "/order-history",
        name: "order-history"
    }
};