import {Metric, onCLS, onINP, onLCP} from 'web-vitals';
import ReactGA from "react-ga4";

export default function reportWebVitals({name, delta, value, id}: Metric) {
    ReactGA.event({
        category: "Web Vitals",
        action: name,
        value: value,
        label: id
    });
}