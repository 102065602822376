import {Button, TextField, Unstable_Grid2 as Grid} from "@mui/material";
import {useAppSelector} from "../../../store/hooks";
import {selectCurrentUser} from "../../auth/AuthSlice";
import React, {PropsWithChildren, useState} from "react";
import {useForm} from "react-hook-form";
import {emailPattern} from "../../../utils/StringUtils";

export interface ReportBugData {
    name?: string;
    email: string;
    message: string;
    attachments: File[];
}

interface ReportBugFormProps extends PropsWithChildren {
    formId: string;
    onSubmit: (data: ReportBugData) => void;
}

function ReportBugForm({ onSubmit, formId, children }: ReportBugFormProps) {

    const user = useAppSelector(selectCurrentUser);
    // Maybe add screenshot support later on
    // const [files, setFiles] = useState<File[]>([]);

    const {
        register,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm<ReportBugData>({
        defaultValues: {
            email: user?.email ?? ""
        }
    });


    return (
        <Grid
            container
            component="form"
            id={formId}
            onSubmit={handleSubmit(onSubmit)}>

            <Grid xs={12}>
                <TextField
                    margin="normal"
                    label="Email"
                    autoComplete="email"
                    fullWidth
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message as string}
                    {
                        ...register("email",{
                            required: "Please provide a contact email address.",
                            pattern: {
                                value: emailPattern,
                                message: "Invalid email address"
                            }
                        })
                    }
                    />
            </Grid>
            <Grid xs={12}>
                <TextField
                    margin="normal"
                    label="Name"
                    fullWidth
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message as string}
                    {...register("name", {})}
                    />
            </Grid>
            <Grid xs={12}>
                <TextField
                    margin="normal"
                    label="Description"
                    fullWidth
                    error={Boolean(errors.message)}
                    helperText={errors.message?.message as string || "Please provide as much information that you can about the technical problem you are experiencing." }
                    multiline
                    minRows={8}
                    {...register("message", {
                        required: "Please provide the details of the technical problem you are experiencing. Please provide as much information as you can."
                    })}
                />
            </Grid>
            {/*<Grid>*/}
            {/*    <Button variant="contained">Add Screen Shot</Button>*/}
            {/*</Grid>*/}
            { children }
        </Grid>
    );
}

export default ReportBugForm;

