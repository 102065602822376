import {Image} from "../images/ImageUtils";
import {Audited, ObjectValues} from "../api/types";

export const productTypeValue = {
    DIGITAL_BOOK: "DIGITAL_BOOK",
    GROUP: "GROUP"
} as const;

export type ProductTypeValue = ObjectValues<typeof productTypeValue>;

export type ProductPrice = {
    id: string;
    productId: string;
    basePrice: number;
    createdDate: number;
    effectiveDate: number;
    expiresDate: number;
}

export interface Product extends Audited {
    id: string;
    title: string;
    subTitle?: string;
    slug: string;
    description?: string;
    shortDescription?: string;
    sku?: string;
    visible: boolean;
    purchasable: boolean;
    enabled: boolean;
    prices?: ProductPrice[];
    type: ProductTypeValue;
    creators: string[];
    images?: Image[];
}

export interface ProductComponent extends Audited {
    groupId: string;
    componentId: string;
    component?: Product;
    quantity: number;
    position?: number;
}

export type ProductComponentRedux = Omit<ProductComponent, "component">;

export interface ProductGroup extends Product {
    type: typeof productTypeValue.GROUP;
    components?: Record<string, ProductComponent>;
}

export interface Book extends Product {
    type: typeof productTypeValue.DIGITAL_BOOK;
    previewLimit: number;
    totalPages: number;
    chapters?: BookChapter[];
}

export type ImageIds = {
    images: string[];
}


export type ProductRedux = Omit<Product, "images"> & ImageIds;
export type ProductGroupRedux = Omit<ProductGroup, "images" | "components"> & ImageIds & { components?: Record<string, ProductComponentRedux> };
export type BookRedux = Omit<Book, "images"> & ImageIds;

export type ProductTypes = Product | ProductGroup | Book;
export type ProductReduxTypes = ProductRedux | ProductGroupRedux | BookRedux;

export interface BookPage extends Audited {
    index: number;
    bookId: string;
    label?: string;
    content: string;
    chapter?: number;
}

export type CreatePages = {
    bookId: string;
    pages: (Omit<BookPage, "bookId" | "createdDate" | "lastModifiedDate" | "createdBy" | "lastModifiedBy" | "chapter">
        & { chapter?: number | string})[];
}

export type UpdatePage = Omit<BookPage, "createdDate" | "lastModifiedDate" | "createdBy" | "lastModifiedBy" | "chapter"> &
    { chapter?: number | string };

export interface ChapterPage {
    index: number;
    pageLabel?: string;
    chapter?: number;
    chapterLabel?: string;
}

export type ChapterPages = ChapterPage[];

export interface BookChapter {
    index: number;
    bookId?: string;
    label?: string;
    title: string;
    pages?: number[];
}

export interface CreateProduct {
    id: string;
    title: string;
    subTitle?: string;
    description?: string;
    shortDescription?: string;

    sku?: string;

    visible: boolean;
    purchasable: boolean;
    enabled: boolean;

    price?: number;
    creators?: string[];
    images?: string[];
    purchaseLimit?: number;
}

export interface CreateBook extends CreateProduct {
    price: number;
    previewLimit?: number;
}

export interface CreateProductGroup extends CreateProduct {
    components?: CreateGroupComponent[];
}

export interface CreateGroupComponent {
    componentId : string;
    quantity: number;
    position?: number;
}


export interface UpdateProduct {
    id: string;
    title: string;
    subTitle?: string;
    description?: string;
    shortDescription?: string;
    sku?: string;

    purchasable: boolean;
    visible: boolean;
    enabled: boolean;

    creators?: string[];
    images?: string[];
}

export interface UpdateBook extends UpdateProduct {
    previewLimit: number;
}

export interface UpdateProductGroup extends UpdateProduct {
    components: CreateGroupComponent[];
}


