import {apiSlice} from "../api/apiSlice";
import {Audited, Collection, Pageable} from "../api/types";
import {AddAspect, CreateImageSet, Image, ImageDimensions, transformImagePath} from "./ImageUtils";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {extendedProductApi} from "../product/ProductSlice";
import {RootState} from "../../store/clientStore";


export const extendedImageApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // TODO: Add get all images endpoint...
        getImage: builder.query<Image, { id: string }>({
            query: ({id}) => ({
                method: "GET",
                url: `/file/image/${id}`,
                accept: "application/json"
            }),
            transformResponse: transformImagePath,
            providesTags: (result, error, arg) =>
                result ? [{type: "Image", id: arg.id}, "Image"] : ["Image"]
        }),
        getAllImages: builder.query<Collection<Audited<Image>>, Pageable>({
            query: ({
                        page = 0, size = 20, sort, filter, expand
                    } = {}) => ({
                url: '/file/image',
                method: "GET",
                params: {page, size, sort, filter, expand}
            }),
            transformResponse: (response: Collection<Audited<Image>>, meta, args) => {
                  response.data.forEach(transformImagePath);
                  return response;
            },
            providesTags: (result, error, arg) =>
                result ? [...result.data.map(image => ({
                    type: "Image" as const,
                    id: image.id
                })), {type: "Image", id: "PARTIAL-LIST"}] : [{type: "Image", id: "PARTIAL-LIST"}]
        }),
        uploadImage: builder.mutation<Image, CreateImageSet>({
            query: ({id, alt, images}) => {

                const formData = new FormData();
                // formData.append("id", id);
                formData.append("alt", new Blob([JSON.stringify(alt)], {type: "application/json"}));
                formData.append("id", new Blob([JSON.stringify(id)], {type: "application/json"}));
                const sizes = [];
                for (const [aspect, img] of Object.entries(images)) {
                    console.log(img.file.name, img.sizes);
                    formData.append("files", img.file);
                    sizes.push(...img.sizes);
                }

                formData.append("sizes", new Blob([JSON.stringify(sizes)], {type: "application/json"}));

                return {
                    method: "POST",
                    url: `/file/image`,
                    body: formData,
                    accept: "application/json",
                    headers: {
                        contentType: "application/json"
                    }
                };
            }
        }),
        updateAlt: builder.mutation<Image, { id: string; alt: string }>({
            query: ({id, alt}) => ({
                method: "POST",
                url: `/file/image/${id}`,
                body: {alt},
                accept: "application/json"
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "Image", id: result.id}, "Image"] : ["Image"]
        }),

        addAspect: builder.mutation<Image, AddAspect>({
            query: ({id, images}) => {
                const formData = new FormData();

                const sizes = [];

                for (const img of images) {
                    console.log(img.file.name, img.sizes);
                    formData.append("files", img.file);
                    sizes.push(...img.sizes);
                }

                formData.append("sizes", new Blob([JSON.stringify(sizes)], {type: "application/json"}));

                return {
                    method: "POST",
                    url: `/file/image/${id}/aspect`,
                    body: formData,
                    accept: "application/json",
                    headers: {
                        contentType: "application/json"
                    }
                };
            },
            invalidatesTags: (result) => result ? [{type: "Image", id: result.id}, "Image"] : ["Image"]
        }),
        addSizes: builder.mutation<Image, { id: string, sizes: ImageDimensions[] }>({
            query: ({id, sizes}) => ({
                method: "POST",
                url: `/file/image/${id}/sizes`,
                body: sizes,
                accept: "application/json"
            }),
            invalidatesTags: (result) => result ? [{type: "Image", id: result.id}, "Image"] : ["Image"]
        }),
        deleteImage: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: "DELETE",
                url: `/file/image/${id}`
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "Image", id: arg.id}, "Image"] : ["Image"]
        }),
        deleteAspect: builder.mutation<Image, { id: string, aspect: string }>({
            query: ({id, aspect}) => ({
                method: "DELETE",
                url: `/file/image/${id}/aspect/${aspect}`,
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "Image", id: arg.id}, "Image"] : ["Image"]
        }),
        deleteImageSize: builder.mutation<Image, { id: string, sizes: ImageDimensions[] }>({
            query: ({id, sizes}) => ({
                method: "DELETE",
                url: `/file/image/${id}/sizes`,
                body: sizes
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "Image", id: arg.id}, "Image"] : ["Image"]
        })
    })
});


type InitialState = Record<string, Image>

const initialState: InitialState = {};

const imageSlice = createSlice({
    name: "image",
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addMatcher(extendedImageApi.endpoints.getImage.matchFulfilled,
                (state, {payload, type}) => {
                    state[payload.id] = payload;
                })
            .addMatcher(extendedImageApi.endpoints.getAllImages.matchFulfilled,
                (state, {payload, type}) => {
                    payload.data.forEach(image => {
                        state[image.id] = image;
                    });
                })
            .addMatcher(extendedProductApi.endpoints.getProduct.matchFulfilled,
                (state, {payload, type}) => {
                    if (payload.images)
                        payload.images.forEach(image => {
                            state[image.id] = transformImagePath(image);
                        })
                })
            .addMatcher(extendedProductApi.endpoints.getProducts.matchFulfilled,
                (state, {payload, type}) => {
                    payload.data.forEach(product => {
                        if (product.images) {
                            product.images.forEach(image => {
                                state[image.id] = transformImagePath(image);
                            });
                        }
                    })
                })
});

export function selectImageById(state: RootState, imageId: string) {
    return state.image[imageId];
}

export const selectImagesByIds = createSelector(
    (state: RootState, ids?: string[]): Record<string, Image> => state.image,
    (state: RootState, ids?: string[]): string[] => ids || [],
    (images: Record<string, Image>, ids: string[]): Record<string, Image> =>
        ids.reduce<Record<string, Image>>((acc, cur) => {
            if (images[cur])
                acc[cur] = images[cur];
            return acc;
        }, {})
);

export const {
    useGetImageQuery,
    useGetAllImagesQuery,
    useUploadImageMutation,
    useUpdateAltMutation,
    useAddAspectMutation,
    useAddSizesMutation,
    useDeleteImageMutation,
    useDeleteAspectMutation,
    useDeleteImageSizeMutation,
} = extendedImageApi;

export default imageSlice.reducer;