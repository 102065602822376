import {configureStore, ThunkAction, Action, combineReducers} from "@reduxjs/toolkit";
import {apiSlice} from "../feature/api/apiSlice";
import authReducer from "../feature/auth/AuthSlice";
import shoppingReducer from "../feature/shopping/ShoppingSlice";
import productReducer from "../feature/product/ProductSlice";
import libraryReducer from "../feature/library/LibrarySlice";
import settingsReducer from "../feature/settings/SettingsSlice";
import imageReducer from "../feature/images/ImageSlice";

import {persistStore, persistReducer} from "redux-persist";

// @ts-ignore: ts-ignore is required because no ts types are defined for this storage type
import storage from 'redux-persist-indexeddb-storage';
import {RtkQueryErrorTransformer} from "../feature/api/RtkQueryErrorTransformer";

const dbStorage = storage(import.meta.env.VITE_APP_STORE_DB);

function clientReducer() {
    return combineReducers({
        "auth": persistReducer({key: "auth", storage: dbStorage, blacklist: ["csrf"]}, authReducer),
        "shopping": persistReducer({key: "shopping", storage: dbStorage, whitelist: ["cart"]}, shoppingReducer),
        "library": persistReducer({
            key: "library",
            storage: dbStorage,
            whitelist: ["book"]
        }, libraryReducer),
        "product": persistReducer({
            key: "product",
            storage: dbStorage,
            whitelist: ["item", "itemIdMap", "book"]
        }, productReducer),
        "image": persistReducer({
           key: "image",
           storage: dbStorage,
        }, imageReducer),
        "settings": persistReducer({
            key: "settings",
            storage: dbStorage,
            whitelist: ["reader", "theme", "product"],
        }, settingsReducer),
        [apiSlice.reducerPath]: apiSlice.reducer
    });
}

const rootReducer = clientReducer();

export const setupStore = (preloadedState?: Partial<RootState>) => configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(apiSlice.middleware)
            .concat(RtkQueryErrorTransformer)
    // .concat(logger)
    ,
    preloadedState
});

// Infer the `RootState` and `AppDispatch` types from the serverStore itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// @ts-ignore
export const clientStore = setupStore(window?.__PRELOADED_STATE__);

// Not sure what this is for...
if (typeof window !== 'undefined') {
    /* @ts-ignore */
    delete window.__PRELOADED_STATE__;
}

export const persistor = persistStore(clientStore);