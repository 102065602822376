import { useCallback, useRef } from "react";

interface UseSwipeProps {
    velocity?: number;
    distance?: number;
    onSwipeLeft: () => void;
    onSwipeRight: () => void;
}

export default function useSwipe({ onSwipeLeft, onSwipeRight, velocity: requiredVelocity = 200, distance: requiredDistance = 100 }: UseSwipeProps) {

    const touchRef = useRef<{ startX?: number; startY?: number; endX?: number; endY?: number; time: number; velocity: number; }>({
        time: 0, velocity: 0
    });


    const onTouchStart = useCallback((e: React.TouchEvent) => {
        touchRef.current.startX = e.targetTouches[0].clientX;
        touchRef.current.startY = e.targetTouches[0].clientY;
        touchRef.current.endX = undefined;
        touchRef.current.endY = undefined;
        touchRef.current.time = Date.now();
    }, [touchRef.current]);

    const onTouchMove = useCallback((e: React.TouchEvent) => {
        touchRef.current.endX = e.targetTouches[0].clientX;
        touchRef.current.endY = e.targetTouches[0].clientY;
    }, [touchRef.current]);

    const onTouchEnd = useCallback((e: React.TouchEvent) => {
        const { startX, startY, endX, endY, time } = touchRef.current;
        if (!startX || !endX || !startY || !endY) return;

        const duration = Date.now() - time;
        const distanceX = Math.abs(startX - endX);
        const distanceY = Math.abs(endY - startY);
        const direction = Math.sign(startX - endX);
        const velocity = (distanceX * 1000) / duration;

        if (velocity > requiredVelocity && distanceX > requiredDistance && distanceX > (distanceY / 2)) {
            if (direction === 1) onSwipeRight();
            if (direction === -1) onSwipeLeft();
        }
    }, [touchRef.current, onSwipeLeft, onSwipeRight]);

    return {
        onTouchStart,
        onTouchMove,
        onTouchEnd
    };
}