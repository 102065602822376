import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import AppMenu from "../components/AppMenu";
import React, {PropsWithChildren} from "react";
import {Typography, Box} from "@mui/material";
import MenuSpacer from "../components/MenuSpacer";
import AppContainer from "../components/AppContainer";


interface AppConProps extends PropsWithChildren {

}

function AppCon({children}: AppConProps) {
    return (
        <Box sx={(theme) => ({
            maxWidth: theme.breakpoints.values.xl,
            minHeight: "100vh",
            mx: "auto",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
            padding: 0
        })}>
            <AppMenu/>
            <MenuSpacer/>
            <AppContainer>
                {children}
            </AppContainer>
        </Box>
    );
}

function RootErrorBoundary() {
    const error = useRouteError();

    console.error(error);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <AppCon>
                    <Typography variant="h1">404 Not Found</Typography>
                </AppCon>
            )
        }
        if (error.status === 401) {
            return (
                <AppCon>
                    <Typography variant="h1">Unauthorized</Typography>
                </AppCon>
            );
        }

        if (error.status === 503) {
            return (
                <AppCon>
                    <Typography variant="h1">Down for maintaince</Typography>
                    <Typography>It's looks like our app is down for maintains, sorry.</Typography>
                    <Typography>It should be back up and running in a few minutes.</Typography>
                </AppCon>
            );
        }

        if (error.status === 500) {
            return (
                <AppCon>
                    <Typography variant="h1">System Error</Typography>
                    <Typography>It looks like a system error has occurred.</Typography>
                    { /* TODO: add contact info */}
                </AppCon>
            )
        }
    }
    return (
        <AppCon>
            <Typography variant="h1">Something went wrong</Typography>
        </AppCon>
    );
}

export default RootErrorBoundary;