import {ReactNode, useContext, useEffect, useId, useState} from "react";

import {
    Typography,
    Box,
    Drawer,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    ListItem,
    Divider,
    List,
    Toolbar,
    IconButton,
    AppBar,
    Theme,
    alpha,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText
} from "@mui/material";

import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentUser, selectIsAuthenticated, useLogoutMutation} from "../feature/auth/AuthSlice";
import {RoutePath} from "../router/RoutePath";
import {ResponseError} from "../feature/api/RtkQueryErrorTransformer";
import ComponentGuard from "./ComponentGuard";

import {
    AccountBox, ChevronRight, History, Login, Logout, QuestionMark, ShoppingCart,
    Menu as MenuIcon, Settings as SettingsIcon, Home as HomeIcon,
    InfoOutlined as InfoOutlinedIcon, InstallMobile as InstallMobileIcon,
    AlternateEmail as AlternateEmailIcon, BugReport as BugReportIcon,
    AdminPanelSettings as AdminPanelSettingsIcon
} from "@mui/icons-material";

import "@fontsource/tangerine/700.css";
import {authority} from "../feature/auth/AuthTypes";
import HideOnScroll from "./HideOnScroll";
import {MenuControlContext} from "./MenuController";
import {SystemStyleObject} from "@mui/system/styleFunctionSx/styleFunctionSx";
import ProgressLoader from "./ProgressLoader";
import {transformErrorMessage} from "../utils/StringUtils";
import {InstallPWAContext} from "./InstallPWAContext";
import ReportBugForm, {ReportBugData} from "../feature/support/forms/ReportBugForm";
import {captureFeedback} from "@sentry/react";


const menuStyling: Record<"main" | "noLogo" | "reader", Record<"appbar" | "menu" | "logo" | "toolbar", (theme: Theme) => SystemStyleObject<Theme>>> = {
    main: {
        appbar: (theme) => ({
            boxShadow: "none",
            backgroundImage: "none",
            // border: `1px solid ${theme.palette.divider}`,
            // backgroundColor: alpha(theme.palette.background.paper, 0.9),
            backdropFilter: "blur(2px)",
            maxWidth: "xl",
            mx: "auto"
        }),
        toolbar: (theme) => ({
            maxWidth: "xl",
            mx: "auto"
        }),
        menu: (theme) => ({
        }),
        logo: (theme) => ({})
    },
    noLogo: {
        appbar: (theme) => ({
            boxShadow: "none",
            backgroundColor: "transparent",
            backgroundImage: "none"
        }),
        toolbar: (theme) => ({
            maxWidth: "xl",
            mx: "auto"
        }),
        menu: (theme) => ({
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
            backdropFilter: "blur(3px)",
            border: `1px solid ${alpha(theme.palette.background.paper, 0.8)}`,
            borderRadius: 2
        }),
        logo: (theme) => ({
            display: "none"
        })
    },
    reader: {
        appbar: (theme) => ({
            boxShadow: "none",
            backgroundImage: "none",
            backgroundColor: "transparent"
        }),
        toolbar: (theme) => ({
            maxWidth: "xl",
            mx: "auto"
        }),
        menu: (theme) => ({
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
            backdropFilter: "blur(3px)",
            border: `1px solid ${alpha(theme.palette.background.paper, 0.8)}`,
            borderRadius: 2
        }),
        logo: (theme) => ({
            display: "none"
        })
    }
} as const;

type MenuKeys = keyof typeof menuStyling;
type FeedBackModel = 'OPEN' | 'SENT' | 'CLOSED';

function AppMenu() {

    const {showTopMenu} = useContext(MenuControlContext);

    const [open, setOpen] = useState(false);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const user = useSelector(selectCurrentUser);
    const [logoutRequest, {error, isError}] = useLogoutMutation();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [menuStyle, setMenuStyle] = useState<MenuKeys>("main");
    const {canInstallPWA, installPWA} = useContext(InstallPWAContext);

    const [feedBackState, setFeedBackState] = useState<FeedBackModel>('CLOSED');
    const reportBugId = useId();

    // TODO: Need to re-write this at some point
    useEffect(() => {
        const locationParts = location?.pathname.trim()
            .split("/")
            .filter(Boolean); // remove falsely values

        if (locationParts[0] === "product" || locationParts[0] === 'about') {
            setMenuStyle("noLogo");
        } else if (locationParts[0] === "book") {
            setMenuStyle("reader")
        } else {
            setMenuStyle("main")
        }
    }, [location]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitBugReport = (data: ReportBugData) => {
        console.log("Sending bug report: ", data);
        captureFeedback(data);
        setFeedBackState('SENT');
    }

    const handleUserLogout = () => {
        if (user) {
            setShowLogoutDialog(true);
            logoutRequest({userId: user.id})
                .then(response => {
                    if (!response.error) {
                        navigate(RoutePath.home);
                        setShowLogoutDialog(false);
                    }
                });
        }
    };

    useEffect(() => {
        if (error) {
            if ("status" in error) {
                const responseError = error as ResponseError;
                console.log("details" in responseError && responseError.details != null ? responseError.details : responseError.error);
            }
        }
    }, [error]);

    const feedBackDialogContent: Record<FeedBackModel, ReactNode> = {
        OPEN: (
            <>
                <DialogContent>
                    {feedBackState === 'OPEN' ?
                        <ReportBugForm
                            formId={reportBugId}
                            onSubmit={handleSubmitBugReport}
                        /> :
                        <>
                            <DialogContentText align="center">You message has been sent.</DialogContentText>
                            <DialogContentText align="center">
                                Thank you for taking the time to let us know that the app isn't working as expected.
                            </DialogContentText>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={() => setFeedBackState('CLOSED')}>Cancel</Button>
                    <Button
                        disabled={feedBackState === 'SENT'}
                        form={reportBugId}
                        type="submit"
                        variant="contained">Send</Button>
                </DialogActions>
            </>
        ),
        SENT: (
            <>
                <DialogContent>
                    <DialogContentText align="center">You message has been sent.</DialogContentText>
                    <DialogContentText align="center">
                        Thank you for taking the time to let us know that the app isn't working as expected.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled
                        color="inherit" onClick={() => setFeedBackState('CLOSED')}>Cancel</Button>
                    <Button
                        onClick={() => setFeedBackState('CLOSED')}
                        variant="contained">Close</Button>
                </DialogActions>
            </>
        ),
        CLOSED: <></>
    };

    return (
        <>
            <HideOnScroll threshold={100} showContent={showTopMenu}>
                <AppBar
                    position="absolute"
                    sx={menuStyling[menuStyle].appbar}>
                    <Box sx={{
                        width: "100%"
                    }}>
                        <Toolbar sx={[{
                            alignItems: "center"
                        }, menuStyling[menuStyle].toolbar]}>
                            <Typography
                                sx={[
                                    menuStyling[menuStyle].logo,
                                    (theme: Theme) => ({
                                        fontFamily: "tangerine",
                                        fontWeight: 700,
                                        fontSize: "2rem",
                                        textShadow: `1px 1px 3px ${theme.palette.primary.main}, 0px 0px 3px ${theme.palette.primary.main}`
                                    })]}>Aurora Books</Typography>

                            <IconButton
                                onClick={handleOpen}
                                // size="medium"
                                edge="start"
                                color="inherit"
                                aria-label="Open Menu"
                                sx={[
                                    menuStyling[menuStyle].menu,
                                    {
                                        marginLeft: "auto",
                                    }]}>
                                <MenuIcon fontSize="inherit"/>
                            </IconButton>
                        </Toolbar>
                    </Box>
                </AppBar>
            </HideOnScroll>

            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}>

                <Box sx={{width: 250}}
                     onClick={handleClose}
                     aria-label="Close Menu"
                     role="presentation">
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton sx={{height: "52.8px"}}>
                                <ListItemIcon>
                                    <ChevronRight/>
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    {canInstallPWA &&
                        <>
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={installPWA}
                                        aria-label="Install App">
                                        <ListItemIcon>
                                            <InstallMobileIcon/>
                                        </ListItemIcon>
                                        <ListItemText>Install App</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider/>
                        </>
                    }
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                role="navigation"
                                aria-label="Home"
                                to={RoutePath.home}>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText>Home</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink}
                                            to={RoutePath.cart.route}
                                            role="navigation"
                                            aria-label="Cart">
                                <ListItemIcon>
                                    <ShoppingCart/>
                                </ListItemIcon>
                                <ListItemText>Cart</ListItemText>
                            </ListItemButton>
                        </ListItem>

                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                role="navigation"
                                aria-label="Settings"
                                component={NavLink}
                                to="/settings">
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                disabled={!isAuthenticated}
                                component={NavLink}
                                role="navigation"
                                aria-label="User Account"
                                to={RoutePath.auth.account.route}>
                                <ListItemIcon>
                                    <AccountBox/>
                                </ListItemIcon>
                                <ListItemText primary="Account"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                disabled={!isAuthenticated}
                                component={NavLink}
                                role="navigation"
                                aria-label="Order History"
                                to={RoutePath.orderHistory.route}>
                                <ListItemIcon>
                                    <History/>
                                </ListItemIcon>
                                <ListItemText primary="Order History"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                role="navigation"
                                aria-label="Frequently Asked Questions"
                                to={RoutePath.faq}>
                                <ListItemIcon>
                                    <QuestionMark/>
                                </ListItemIcon>
                                <ListItemText primary="FAQ"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => setFeedBackState('OPEN')}
                                aria-label="Create Bug Report">
                                <ListItemIcon>
                                    <BugReportIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Report Bug"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                role="navigation"
                                aria-label="Contact Us"
                                to={RoutePath.contact}>
                                <ListItemIcon>
                                    <AlternateEmailIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Contact"/>
                            </ListItemButton>
                        </ListItem>

                    </List>
                    <ComponentGuard
                        hasAny={[authority.viewGroup, authority.viewProduct, authority.viewImage, authority.viewUser]}>
                        <Divider/>
                        <List disablePadding>
                            <ListItem disablePadding>
                                <ListItemButton component={NavLink} to={RoutePath.admin.route}>
                                    <ListItemIcon>
                                        <AdminPanelSettingsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Admin"/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </ComponentGuard>

                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={RoutePath.about}>
                                <ListItemIcon>
                                    <InfoOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="About"/>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Divider/>
                    <List disablePadding>
                        <ComponentGuard isAuthenticated={true}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    aria-label="Log Out"
                                    onClick={handleUserLogout}>
                                    <ListItemIcon>
                                        <Logout/>
                                    </ListItemIcon>
                                    <ListItemText primary="Sign-Out"/>
                                </ListItemButton>
                            </ListItem>
                        </ComponentGuard>

                        <ComponentGuard isAuthenticated={false}>
                            <ListItem disablePadding>
                                <ListItemButton component={NavLink}
                                                to={RoutePath.auth.signIn.route}
                                                aria-label="Sign In">
                                    <ListItemIcon>
                                        <Login/>
                                    </ListItemIcon>
                                    <ListItemText primary="Sign-In"/>
                                </ListItemButton>
                            </ListItem>
                        </ComponentGuard>
                    </List>
                </Box>
            </Drawer>

            <Dialog open={showLogoutDialog} aria-modal>
                <DialogTitle>Logging Out</DialogTitle>
                <DialogContent>
                    {isError ?
                        <>
                            <DialogContentText>Error logging out.</DialogContentText>
                            {transformErrorMessage(error).map((m, i) =>
                                <DialogContentText key={i} fontSize="0.9em">{m}</DialogContentText>
                            )}
                        </> : <ProgressLoader/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={!isError}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={feedBackState !== 'CLOSED'} aria-modal>
                <DialogTitle>Report Bug</DialogTitle>
                {feedBackDialogContent[feedBackState]}
            </Dialog>

        </>
    );
}

export default AppMenu;