import React from "react";
import {alpha, CircularProgress, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";


interface ProgressLoaderProps {
    size?: string | number;
    text?: string;
    displayText?: boolean;
    thickness?: number;
    sx?: SxProps<Theme>;
}

export default React.forwardRef<HTMLDivElement, ProgressLoaderProps>(
    function ({
                  sx,
                  text = "Loading...",
                  displayText = true,
                  size = "3rem",
                  thickness = 2,
              } = {}, ref) {
        return (
            <Grid container ref={ref}
                  alignContent="center"
                  sx={[{
                      height: "100%" // Possibly remove this
                  },
                      ...(Array.isArray(sx) ? sx : [sx])
                  ]} spacing={4}>
                <Grid xs={12} display="flex" justifyContent="center">
                    <CircularProgress size={size} thickness={thickness}/>
                </Grid>
                {
                    displayText &&
                    <Grid xs={12}>
                        <Typography textAlign="center"
                                    sx={theme => ({
                                        color: alpha(theme.palette.text.primary, 0.3)
                                    })}
                        >{text}</Typography>
                    </Grid>
                }
            </Grid>
        );
    });