import {Image as ImageIcon} from '@mui/icons-material';
import useGetNodeSize from "./hooks/useGetNodeSize";
import useGetWindowSize from "./hooks/useGetWindowSize";
import {useImperativeHandle, useMemo, useRef} from "react";
import {Box, SxProps, Theme, useTheme} from "@mui/material";
import Img from "./Img";
import * as React from "react";
import {Breakpoint} from "@mui/system/createTheme";
import {calculateImageAspect} from "../feature/images/ImageUtils";
import useCssAspectRatio from "./hooks/useCssAspectRatio";

export type ImageSource = {
    source: string;
    width: number;
    height: number;
    aspect?: string;
}

interface ResponsiveImgProps {
    images: ImageSource[];
    sx?: SxProps<Theme>;
    alt?: string;
    aspect?: string | Partial<Record<Breakpoint, string>>;
}


export default React.forwardRef(
    function ResponsiveImg({images: partialImages, sx, alt, aspect}: ResponsiveImgProps, ref) {

        const theme = useTheme();

        const nodeRef = useRef<HTMLImageElement>(null);
        useImperativeHandle(ref, () => nodeRef.current!, [nodeRef]);

        const domSize = useGetNodeSize(nodeRef, 250);
        const windowSize = useGetWindowSize(500);

        const images = useMemo(() => partialImages.map(image => {
            // If the image aspect has not been provided, calculate it
            if (image.aspect === undefined)
                image.aspect = calculateImageAspect(image.width, image.height);
            return image;
        }), [partialImages]);

        // Find the correct aspect for the window size, if this value was provided
        const selectedAspect = useMemo(() => {
            if (typeof aspect === "string")
                return aspect;

            else if (typeof aspect === "object") {

                const bps = Object.entries(theme.breakpoints.values).sort(
                    ([key1, value1], [key2, value2]) => value1 - value2);

                const breakpoints: Breakpoint[] = bps.filter(([key, value]) => value < windowSize.width)
                    .map(([k, v]) => k as Breakpoint)
                    .reverse();

                for (let i = 0; i < breakpoints.length; ++i) {
                    if (aspect[breakpoints[i]])
                        return aspect[breakpoints[i]];
                }
            }
        }, [aspect, windowSize]);

        // Filter out any images that do not match the requested aspect ratio,
        // if no aspect ratio is provided, sort images by width and return all images
        const filteredImages = useMemo(() => {
                if (selectedAspect)
                    return images
                        .filter(image => image.aspect === selectedAspect)
                        .sort((a, b) => a.width - b.width);
                return images.sort((a, b) => a.width - b.width);
            },
            [images, selectedAspect]);

        const selectedImage = useMemo(() => {
            if (filteredImages.length === 0)
                return undefined;

            let selected = filteredImages[0];

            for (let i = 1; i < filteredImages.length - 1; ++i) {
                if (selected.width < domSize.width) {
                    selected = filteredImages[i];
                } else {
                    // Break for loop
                    break;
                }
            }
            return selected;
        }, [domSize, filteredImages]);


        const cssAspect = useCssAspectRatio(selectedAspect);

        if (!selectedImage) {
            return (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    style={{
                        aspectRatio: cssAspect
                    }}>
                    <ImageIcon sx={{
                        fontSize: "3.5rem",
                        color: "rgba(255,255,255,0.12)"
                    }}/>
                </Box>
            );
        }


        return (
            <Img
                src={selectedImage.source}
                ref={nodeRef}
                alt={alt}
                sx={[
                    {
                        width: "100%",
                    },
                    ...(Array.isArray(sx) ? sx : [sx])
                ]}
                style={{
                    aspectRatio: cssAspect
                }}
            />
        );
    })