import {RefObject} from "react";
import {useDebounce} from "@react-hook/debounce";
import useResizeObserver from "@react-hook/resize-observer";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";


function isRef<T extends HTMLElement>(target: RefObject<T> | any): target is RefObject<T> {
    return (target as RefObject<T>).current !== undefined;
}

function isHtmlElement<T extends HTMLElement>(target: T | any): target is T {
    return (target as T).getBoundingClientRect !== undefined;
}

/**
 * Return the size of the provided node reference.
 * @param target Node target, can be a RefObject or an html element
 * @param delay debounce delay in milliseconds
 * @returns 
 */
function useGetNodeSize<T extends HTMLElement>(target: RefObject<T> | T | null,
                                                delay: number = 250) {

    const [size, setSize] = useDebounce<DOMRectReadOnly>(new DOMRect(), delay, true);

    useIsomorphicLayoutEffect(() => {
        if (isRef(target) && target.current)
            setSize(target.current.getBoundingClientRect());
        else if (isHtmlElement(target))
            setSize(target.getBoundingClientRect());
    }, [delay]);

    useResizeObserver(target, entry => setSize(entry.contentRect));

    return size;
}

export default useGetNodeSize;