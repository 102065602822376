import React, {createContext, PropsWithChildren, useState} from "react";

export const MenuControlContext = createContext<{
        showBottomMenu: boolean, setShowBottomMenu: React.Dispatch<React.SetStateAction<boolean>>,
        showTopMenu: boolean, setShowTopMenu: React.Dispatch<React.SetStateAction<boolean>>
    }>({
    showBottomMenu: false,
    setShowBottomMenu: ((display: boolean) => null) as React.Dispatch<React.SetStateAction<boolean>>,
    showTopMenu: true,
    setShowTopMenu: ((display: boolean) => null) as React.Dispatch<React.SetStateAction<boolean>>,
});


export function MenuControl(props: PropsWithChildren<{}>) {

    const [showBottomMenu, setShowBottomMenu] = useState(true);
    const [showTopMenu, setShowTopMenu] = useState(true);

    const menuController = {
        showBottomMenu,
        setShowBottomMenu,
        showTopMenu,
        setShowTopMenu
    };

    return (
        <MenuControlContext.Provider
            value={menuController}
            {...props}
        />
    );
}