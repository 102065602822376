import React from "react";
import {Typography, Unstable_Grid2 as Grid } from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";


interface DisplayErrorProps {
    title?: string;
    message?: string;
    showDetails?: boolean;
    showTitle?: boolean;
    errorMessage?: string[];
    sx?: SxProps<Theme>;
}


export default React.forwardRef<HTMLDivElement, DisplayErrorProps>(
    function DisplayError({
                              title = "Error Loading Page",
                              message = "Sorry, but it appears an error has occurred loading the current page.",
                              showDetails = true,
                              showTitle = true,
                              errorMessage,
                              sx
                          }, ref) {

        return (
            <Grid container ref={ref} alignContent="center"
                  sx={[{
                      height: "100%",
                  },
                      ...(Array.isArray(sx) ? sx : [sx])
                  ]} spacing={2}>
                {showTitle &&
                    <Grid xs={12}>
                        <Typography variant="h1" align="center">{title}</Typography>
                    </Grid>
                }
                <Grid xs={12}>
                    <Typography align="center">{message}</Typography>
                </Grid>

                {showDetails && errorMessage &&
                    <Grid xs={12}>
                        <Typography align="center" fontSize="0.9rem">Details:</Typography>
                        {errorMessage.map((m, i) =>
                            <Typography align="center" key={i} fontSize="0.9rem">{m}</Typography>)
                        }
                    </Grid>
                }
            </Grid>
        );
    });