import {useEffect} from "react";
import {useDebounce} from "@react-hook/debounce";

/**
 * Return the size of the window, debounced by delay.
 * @param delay update delay, in milliseconds
 * @returns 
 */
function useGetWindowSize(delay: number = 500) {

    const [size, setSize] = useDebounce({ width: window.innerWidth, height: window.innerHeight }, delay, true);

    useEffect(() => {
        const onResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        addEventListener("resize", onResize);

        return () => removeEventListener("resize", onResize);
    }, []);


    return size;
}

export default useGetWindowSize;