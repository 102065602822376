import React from "react";
import {Slide, useScrollTrigger} from "@mui/material";

interface HideOnScrollProps {
    window?: () => Window;
    children: React.ReactElement;
    direction?: "down" | "up",
    threshold?: number;
    showContent?: boolean;
}

export default React.forwardRef(function HideOnScroll({window, children, direction = "down", threshold = 100, showContent = true}: HideOnScrollProps, ref) {

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        threshold,
    });

    return (
        <>
            <Slide
                appear={false}
                direction={direction}
                in={showContent && !trigger}
                ref={ref}>

                { children }

            </Slide>
        </>
    );

});