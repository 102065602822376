import {useMemo} from "react";

interface DisplayPriceProps {
    children?: string | number;
}

function DisplayPrice({children}: DisplayPriceProps) {

    return useMemo(() => {
        const p = typeof children === "string" ? parseInt(children) : children;
        if (p === undefined || isNaN(p)) return "";

        const value = Math.abs(p);
        const [dollars, cents] = [Math.floor(value / 100), value % 100];
        return "$ " + Math.sign(p) * dollars + "." + "0".repeat(2 - cents.toString().length) + cents;

    }, [children]);
}

export default DisplayPrice;