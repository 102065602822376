import {createTheme, darken, lighten, ThemeProvider} from "@mui/material";
import {Components} from "@mui/material/styles/components";
import {Theme as MuiTheme} from "@mui/material/styles/createTheme";
import merge from "deepmerge";
import {ReactNode} from "react";
import {ProgressIndicatorRoot} from "./feature/product/components/ProgressIndicator";
import Scrollbar from "./utils/ScrollBars";


declare module '@mui/material/styles' {
    interface CirclePercentage {
        CirclePercentage: "root" | "indicator" | "background"
    }

    interface Components {
        CirclePercentage?: {},
    }

    interface ProgressIndicator {
        ProgressIndicator: "root"
    }

    interface ProgressIndicatorPropsList {
        ProgressIndicator: Partial<ProgressIndicatorRoot>;
    }

    interface Components {
        ProgressIndicator?: {
            defaultProps?: ProgressIndicatorPropsList['ProgressIndicator'];
        }
    }
}


declare module '@mui/material/Toolbar' {
    interface ToolbarPropsVariantOverrides {
        bottom: true;
    }
}


type ThemeColor = {
    main: string;
    light?: string;
    dark?: string;
    contrastText?: string;
};

interface BuildTheme {
    mode?: "dark" | "light";
    common?: {
        black: string;
        white: string;
    };
    primary?: ThemeColor;
    secondary?: ThemeColor;
    error?: ThemeColor;
    warning?: ThemeColor;
    info?: ThemeColor;
    success?: ThemeColor;
    grey?: {
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
        A100: string;
        A200: string;
        A400: string;
        A700: string;
    };
    text?: {
        primary: string;
        secondary: string;
        disabled: string;
        icon: string;
    };
    background?: {
        paper: string;
        default: string;
    };
    components?: Components<MuiTheme>;
}


const baseTheme: MuiTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 600,
            lg: 800,
            xl: 900
        }
    },
});

const baseComponents: Components<MuiTheme> = {
    MuiDialogContent: {
        styleOverrides: {
            root: {
                '&::-webkit-scrollbar': {
                    width: "6px"
                },
            }
        }
    },
    MuiDialog: {
        defaultProps: {
            maxWidth: "md"
        }
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                fontSize: "1.2rem",
                textAlign: "center",
                [baseTheme.breakpoints.up("sm")]: {
                    fontSize: "1.4rem"
                },
                [baseTheme.breakpoints.up("md")]: {
                    fontSize: "1.6rem"
                }
            }
        }
    },
    MuiContainer: {
        styleOverrides: {
            root: {}
        }
    },
    ProgressIndicator: {
        defaultProps: {
            position: "relative",
            backgroundColor: "rgba(15,15,15,0.82)"
        }
    },
    CirclePercentage: {
        styleOverrides: {
            root: {},
            indicator: {
                stroke: "#11c9ff",
            },
            background: {}
        }
    },
    MuiToolbar: {
        variants: [
            {
                props: {variant: "bottom"},
                style: {
                    backgroundColor: "#131313",
                    paddingLeft: 8,
                    paddingRight: 8,
                    minHeight: 56
                }
            }
        ],
        styleOverrides: {
            root: {}
        }
    }
};


function buildTheme({
                        mode = "dark",
                        common = {
                            black: "#000",
                            white: "#fff"
                        },
                        primary,
                        secondary,
                        error,
                        info,
                        success,
                        grey,
                        text,
                        background,
                        components = {}
                    }: BuildTheme = {}) {


    return createTheme({
        breakpoints: {
            values: baseTheme.breakpoints.values
        },
        palette: {
            mode,
            common,
            primary,
            secondary,
            // error,
            // info,
            // success,
            // grey,
            // text,
            background,
        },
        components: merge(baseComponents, components) as Components<MuiTheme>,
        typography: {
            fontFamily: [
                'Libre Caslon Text',
                "Georgia",
                "serif"
            ].join(","),

            h1: {
                fontSize: '1.8rem',
                fontWeight: 400,
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '2rem'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '2.2rem'
                }
            },
            h2: {
                fontSize: "1.6rem",
                fontWeight: 400,
                [baseTheme.breakpoints.up('sm')]: {
                    fontSize: '1.8rem'
                },
                [baseTheme.breakpoints.up('md')]: {
                    fontSize: '2rem'
                }
            },
            h3: {
                fontSize: "1.2rem",
                fontWeight: 400,
                [baseTheme.breakpoints.up("sm")]: {
                    fontSize: "1.4rem"
                },
                [baseTheme.breakpoints.up("md")]: {
                    fontSize: "1.6rem"
                }
            },
            h4: {
                fontSize: "1",
                fontWeight: 400,
                [baseTheme.breakpoints.up("sm")]: {
                    fontSize: "1.2rem",
                },
                [baseTheme.breakpoints.up("md")]: {
                    fontSize: "1.4rem"
                }
            },
            h5: {
                fontSize: ".75",
                fontWeight: 400,
                [baseTheme.breakpoints.up("sm")]: {
                    fontSize: "0.9rem",
                },
                [baseTheme.breakpoints.up("md")]: {
                    fontSize: "1rem"
                }
            },
            h6: {
                fontSize: ".65",
                fontWeight: 400,
                [baseTheme.breakpoints.up("sm")]: {
                    fontSize: "0.8rem",
                },
                [baseTheme.breakpoints.up("md")]: {
                    fontSize: "0.9rem"
                }
            }
        }
    });
}


const themes: Record<string, () => MuiTheme> = {
    Midnight: (): MuiTheme => buildTheme({
        mode: "dark",
        background: {
            default: "#171717",
            paper: "#1a1a1a"
        },
        primary: {
            main: "#713ab7"
        },
        secondary: {
            // main: "#5a35ff"
            main: "#f50057"
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: () => ({
                    html: {
                        ...Scrollbar({
                            track: "#212121",
                            thumb: "#3d3d3d",
                            active: "#575757",
                            width: "6px"
                        })
                    },
                    body: {},
                })
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundImage: "linear-gradient(300deg, rgba(255,255,255, 0.03), rgba(255,255,255, 0.01))",
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        width: "calc(100% - 24px)",
                        margin: "32px 24px",
                        backgroundImage: "linear-gradient(rgba(255,255,255,0.08), rgba(255,255,255,0.04))",
                        ['&.MuiDialog-paperFullScreen']: {
                            width: "100%",
                            margin: 0
                        }
                    },
                }
            },

            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: lighten('#713ab7', .2)
                        }
                    },
                    input: {
                        '&:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #713ab7 inset',
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: lighten('#713ab7', .2)
                        }
                    },
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&.active': {
                            backgroundColor: darken('#713ab7', .3),
                        }
                    }
                }
            }
            // MuiFormLabel: {
            //     styleOverrides: {
            //         root: {
            //             // '&.Mui-focused': {
            //             //     color: "white"
            //             // }
            //         }
            //     }
            // },
        }
    }),
    // Light: () => buildTheme({
    //     mode: "light",
    //     components: {
    //         MuiCssBaseline: {
    //             styleOverrides: {
    //                 html: {},
    //                 body: {
    //                     ...darkScrollbar(),
    //                     backgroundColor: "#222222",
    //                     '&::-webkit-scrollbar': {
    //                         width: "10px"
    //                     },
    //                 },
    //             }
    //         },
    //         CirclePercentage: {
    //             styleOverrides: {
    //                 root: {},
    //                 indicator: {
    //                     stroke: "#208ad5",
    //                 },
    //                 background: {}
    //             }
    //         },
    //         MuiDialogContent: {
    //             styleOverrides: {
    //                 root: {
    //                     '&::-webkit-scrollbar': {
    //                         width: "8px"
    //                     },
    //                 }
    //             }
    //         }
    //     }
    // })
};

export type ThemeKeys = keyof typeof themes;

function AbstractThemeProvider({children}: { children: ReactNode }) {

    const themeName = "Midnight";
    const theme: MuiTheme = themes[themeName]();

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}


export default AbstractThemeProvider;
