import {
    LibraryPreferences,
    ProductGroupSettings,
    ProductSettings,
    ReaderPreferences,
    SwipeOrientation, TextAlign,
    TransitionPreferences
} from "./SettingsTypes";
import {ThemeKeys} from "../../Theme";
import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/clientStore";
import {extendedProductApi} from "../product/ProductSlice";
import {productTypeValue} from "../product/ProductTypes";
import {objectsEqual} from "../../utils/Utils";

interface InitialState {
    library: LibraryPreferences;
    product: ProductSettings;
    theme: ThemeKeys;
    reader: ReaderPreferences;
}

const initialState: InitialState = {
    library: {
        loadCount: 20,
        swipeDeadZone: 100,
        pageControls: {
            display: "auto",
            showButton: "auto",
            pageNumberDisplay: "withTotal"
        },
        transitions: {
            duration: {
                swipe: 500,
                fade: 250
            },
            type: "swipe",
            swipe: {
                direction: "horizontal",
                defaultDirection: "horizontal",
                matchGestures: false
            }
        }
    },
    product: {
        group: {}
    },
    theme: "Midnight",
    reader: {
        base: {
            fontSize: 18,
            lineHeight: 1.8,
            marginY: 2,
        },
        body: {
            fontSize: 1,
            marginY: 0,
            textAlign: "justify",
            fontWeight: 400,
        },
        h1: {
            fontSize: 2,
            marginY: 1,
            textAlign: "center",
            fontWeight: 500
        },
        blockquote: {
            fontSize: 1.2,
            marginY: 1,
            textAlign: "right",
        }
    }
};

const settingsSlice = createSlice(({
    name: "settings",
    initialState,
    reducers: {
        updateSwipeDirection: (state, {payload: {direction}, type}: PayloadAction<{ direction: SwipeOrientation }>) => {
            state.library.transitions.swipe.direction = direction;
        },
        setTheme: (state, {payload: {theme}, type}: PayloadAction<{ theme: ThemeKeys }>) => {
            state.theme = theme;
        },
        updateReader: (state, {payload, type}: PayloadAction<ReaderPreferences>) => {
            state.reader = {
                ...state.reader,
                ...payload
            };
        },
        updateFontSize: (state, {payload, type}: PayloadAction<number>) => {
            state.reader.base.fontSize = payload;
        },
        updateFontWeight: (state, {payload, type}: PayloadAction<number>) => {
            state.reader.body.fontWeight = payload;
            state.reader.h1.fontWeight = payload + 100;
        },
        updateFontLineHeight: (state, {payload, type}: PayloadAction<number>) => {
            state.reader.base.lineHeight = payload;
        },
        updateFontMargin: (state, {payload, type}: PayloadAction<number>) => {
            state.reader.base.marginY = payload;
        },
        updateFontBodyTextAlign: (state, {payload, type}: PayloadAction<TextAlign>) => {
            state.reader.body.textAlign = payload;
        },
        resetDefault: (state) => {
            state.reader = structuredClone(initialState.reader);
        },
        setGroupPosition: (state, {payload: {id, position}}: PayloadAction<{ id: string; position: number }>) => {
            // The first case should never happen. Remove it??
            if (state.product.group[id] == undefined) {
                state.product.group[id] = {position: position};
            } else {
                state.product.group[id].position = position;
            }
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(extendedProductApi.endpoints.getAllGroups.matchFulfilled,
                (state, {payload, meta}) => {
                    payload.data.forEach(({id}) => {
                        if (state.product.group[id] === undefined) {
                            state.product.group[id] = {
                                position: 0
                            }
                        }
                    });
                }
            )
            .addMatcher(extendedProductApi.endpoints.getGroup.matchFulfilled,
                (state, {payload: {id}}) => {
                    if (state.product.group[id] === undefined) {
                        state.product.group[id] = {
                            position: 0
                        };
                    }
                }
            )
            .addMatcher(extendedProductApi.endpoints.getProduct.matchFulfilled,
                (state, {payload: {id, type}}) => {
                    if (type === productTypeValue.GROUP &&
                        state.product.group[id] === undefined) {
                        state.product.group[id] = {
                            position: 0
                        };
                    }
                }
            )
            .addMatcher(extendedProductApi.endpoints.getProducts.matchFulfilled,
                (state, {payload}) => {
                    payload.data.forEach(({id, type}) => {
                        if (type === productTypeValue.GROUP &&
                            state.product.group[id] === undefined) {
                            state.product.group[id] = {
                                position: 0
                            };
                        }
                    })
                }
            )
    }
}));

export const selectIsTextPreferenceModified = createSelector(
    (state: RootState) => state.settings.reader,
    (readerPref: ReaderPreferences) => objectsEqual(initialState.reader, readerPref)
);

export const selectTheme = (state: RootState) => state.settings.theme;
export const selectLoadCount = (state: RootState) => state.settings.library.loadCount ?? 10;
export const selectLibraryPreferences = (state: RootState): LibraryPreferences => state.settings.library;
export const selectTransitionPreferences = (state: RootState): TransitionPreferences => state.settings.library.transitions;
export const selectPageDisplayPref = (state: RootState): "number" | "withTotal" | "none" => state.settings.library.pageControls.pageNumberDisplay;
export const selectReaderPref = (state: RootState): ReaderPreferences => state.settings.reader;
export const selectGroupSettings = (state: RootState, id?: string): ProductGroupSettings | undefined =>
    id ? state.settings.product.group[id] : undefined;


export const {
    updateSwipeDirection,
    setTheme,
    updateReader,
    updateFontSize,
    updateFontWeight,
    updateFontLineHeight,
    updateFontMargin,
    updateFontBodyTextAlign,
    resetDefault,
    setGroupPosition
} = settingsSlice.actions;


export default settingsSlice.reducer;