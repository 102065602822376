import { ObjectValues } from "../api/types";

export const authority = {
    viewUser: "VIEW_USER",
    editUser: "EDIT_USER",
    createUser: "CREATE_USER",

    viewUserProfile: "VIEW_USER_PROFILE",
    editUserProfile: "EDIT_USER_PROFILE",
    createUserProfile: "CREATE_USER_PROFILE",

    viewRole: "VIEW_ROLES",
    editRole: "EDIT_ROLE",
    createRole: "CREATE_ROLE",
    deleteRole: "DELETE_ROLE",

    viewAuthorities: "VIEW_AUTHORITIES",

    viewProduct: "VIEW_PRODUCT",
    editProduct: "EDIT_PRODUCT",
    createProduct: "CREATE_PRODUCT",
    deleteProduct: "DELETE_PRODUCT",

    viewBook: "VIEW_BOOK",
    createBook: "CREATE_BOOK",
    editBook: "EDIT_BOOK",
    deleteBook: "DELETE_BOOK",

    viewGroup: "VIEW_GROUP",
    createGroup: "CREATE_GROUP",
    editGroup: "EDIT_GROUP",
    deleteGroup: "DELETE_GROUP",

    user: "USER",
    oAuthUser: "OAUTH_USER",

    viewImage: "VIEW_IMAGE",
    createImage: "CREATE_IMAGE",
    editImage: "EDIT_IMAGE",
    deleteImage: "DELETE_IMAGE",

    viewUsersLibrary: "VIEW_USERS_LIBRARY",
    editUsersLibrary: "EDIT_USERS_LIBRARY",
    createUsersLibrary: "CREATE_USERS_LIBRARY"

} as const;

export type Authority = ObjectValues<typeof authority>;


// Has Any Authority helper function...
// function hasAnyAuthority(authorities: Authority[]) {
//     return function _hasAnyAuthority(userAuthorities?: Authority[]) {
//
//     }
// }